import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IAdminDashboard, IDailySettlement, IDailySettlementList, IGetUserInfo } from 'entities/Auth';
import { IDailyPropertyManagementTransactionSettlement } from '@dm/types';
import { LoginParams, LoginResponse, IAuthGateway, GetUserInfoParams, GetAdminDashboardParams, GetDailySettlementParams, ApproveDailySettlementParams } from './AuthBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});
export default class AuthGateway extends IAuthGateway {
    async login(params: LoginParams): GatewayResponse<LoginResponse | null> {
        const response: ApiResponse<LoginResponse> = await this.api.post('auth/login', params);
        return this.process(response);
    }

    async getUserInfo(params: GetUserInfoParams): GatewayResponse<IGetUserInfo> {
        const response: ApiResponse<IGetUserInfo> = await this.api.get('admin/user/getUserInfo', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getAdminDashboard(params: GetAdminDashboardParams): GatewayResponse<IAdminDashboard> {
        const response: ApiResponse<IAdminDashboard> = await this.api.get('admin/dashboard/getDashboard', {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getDailySettlement(params: GetDailySettlementParams): GatewayResponse<IDailySettlementList> {
        const { authToken, index } = params;
        const response: ApiResponse<IDailySettlementList> = await this.api.get('admin/package/propertyManagement/getDailyPropertyManagementTransactionSettlement', { index }, getHeaders(authToken));
        return this.process(response);
    }

    async approveDailySettlement(params: ApproveDailySettlementParams): GatewayResponse<string | null> {
        const { authToken, id, status } = params;

        const response: ApiResponse<string> = await this.api.put(`admin/package/propertyManagement/dailyPropertyManagementTransactionSettlement?id=${id}&status=${status}`, { }, getHeaders(authToken));
        return this.process(response);
    }
}
