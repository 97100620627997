import React, { FunctionComponent, useState, useEffect } from 'react';

import Translate from 'lib/translate';
import styled from 'styled-components';

import NavActions from 'lib/NavActions';

import { PMTransactionTypeEnum, ViewAllPMTransactionResponse } from '@dm/types';
import dayjs from 'dayjs';
import { Colors } from '@dm/bigfish';

interface ITransactionDetails {
    propertyName: string;
    unitNo: string;
    amount: number;
    transactionType: PMTransactionTypeEnum;
    description: string;
}
interface PopoverProps {
    date: string;
    amount: number;
    transactionsDetails: ITransactionDetails[];
}

const Popover: FunctionComponent<PopoverProps> = (props: PopoverProps) => {
    const {
        date,
        amount,
        transactionsDetails,
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (

        <PopoverButton
            onClick={() => {
                setIsOpen(!isOpen);
            }}
            onBlur={() => setIsOpen(false)}
        >
            <div style={{ flexDirection: 'column' }}>
                <p>{dayjs(date).format('DD-MM-YYYY')}</p>
                <p style={{ color: `${Colors.grey}`, fontSize: '14px' }}>{`Count: ${transactionsDetails.length}`}</p>
                <p style={{ color: `${Colors.grey}`, fontSize: '14px' }}>{`Amount: RM${amount}`}</p>
            </div>
            {
                isOpen && transactionsDetails.length > 0 ? (
                    <PopoverContainer>
                        {
                            transactionsDetails?.map((item) => {
                                return (
                                    <PopoverContentContainer>
                                        <PopoverText>
                                            <PopoverNav onClick={() => null}>
                                                {item.propertyName ? item.propertyName.trim() : ''}
                                            </PopoverNav>
                                        </PopoverText>
                                        <PopoverText>
                                            <PopoverNav onClick={() => null}>
                                                {(!item.unitNo || item.unitNo?.length === 0) ? 'N/A' : item.unitNo.trim()}
                                            </PopoverNav>
                                        </PopoverText>
                                        <PopoverText style={{ fontWeight: 'bold' }}>
                                            <PopoverNav onClick={() => null}>
                                                {`RM${item.amount}`}
                                            </PopoverNav>
                                        </PopoverText>
                                        <PopoverText>
                                            <PopoverNav onClick={() => null}>
                                                {PMTransactionTypeEnum[`${item.transactionType}`]}
                                            </PopoverNav>
                                        </PopoverText>
                                        <PopoverText>
                                            <PopoverNav onClick={() => null}>
                                                {item.description}
                                            </PopoverNav>
                                        </PopoverText>
                                    </PopoverContentContainer>
                                );
                            })
                        }

                    </PopoverContainer>
                ) : null
            }
        </PopoverButton>

    );
};

const PopoverButton = styled.button`
    all: unset;
    position: relative;
    cursor: pointer;
`;

const PopoverNav = styled.span`
 &:hover {
        color: black;
    }
`;

const PopoverContentContainer = styled.div`
    width: fit-content;
    /* display: flex; */
    /* gap: 1em; */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-column-gap: 1rem; */
`;

const PopoverText = styled.div`
    font-size: 13px;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
    text-align: justify;
    width: fit-content;
`;

const PopoverUnitNoText = styled(PopoverText)`
    /* font-weight: bold; */
    min-width: 50px;
    max-width: 50px;
`;

const PopoverPropertyNameText = styled(PopoverText)`
    text-align: justify;
    width: fit-content;
    max-width: 160px;
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding-block: 1rem;
  background: white;
  z-index: 9999;
  width: fit-content;
  height: 100px;
  min-width: 500px;
  max-width: 650px;
  scrollbar-gutter: stable both-edges;
  overflow-y: overlay;
  box-shadow: #0000001f 0px 1px 3px, #0000003d 0px 1px 2px;
  cursor: default;
  border-radius: 10px;
  &::-webkit-scrollbar {
    width: 1.25em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }  

  &:hover {
    &::-webkit-scrollbar-thumb {
        background-color: #F7C48E;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #F2B47E;
  }



`;

export default Popover;
