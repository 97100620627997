import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminDashboard, IDailySettlement, IGetUserInfo } from 'entities/Auth';
import { LoginActionPayload, AuthReduxState, GetDailySettlementActionPayload, ApproveDailySettlementActionPayload } from './types';

const initialState: AuthReduxState = {
    actions: {
        login: false,
        startup: true,
        getUserInfo: false,
        getAdminDashboard: false,
        getDailySettlement: false,
        approveDailySettlement: false,
    },
    authToken: '',
    getUserInfo: {
        userId: '',
        email: '',
        role: 0,
    },
    getAdminDashboard: {
        pendingPaymentApproval: {
            total: 0,
            pendingPaymentData: [{
                owner: '',
                receiptCount: 0,
                amount: 0,
                propertyName: '',
                propertyId: '',
                paymentId: '',
            }],
        },
        transactionPendingCompletion: {
            total: 0,
            transactionPendingCompletionData: [{
                amount: 0,
                description: '',
                name: '',
                propertyName: '',
                propertyId: '',
                transactionId: '',
                transactionType: 0,
                fileurl: [],
            }],
        },
        pendingPropertyValidation: {
            total: 0,
            pendingPropertyValidationData: [{
                propertyId: '',
                property: '',
                owner: '',
                description: '',
            }],
        },
    },
    dailySettlement: [],
    isSuperAdmin: false,
    isApproveSettlementModalOpen: false,
    error: {
        login: '',
        startup: '',
        getUserInfo: '',
        getAdminDashboard: '',
        getDailySettlement: '',
        approveDailySettlement: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authStartupAttempt: (state) => {
            state.actions.startup = true;
            state.error.startup = '';
        },
        authStartupSuccess: (state) => {
            state.actions.startup = false;
        },
        authStartupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.startup = false;
            if (action.payload) state.error.startup = action.payload;
        },

        authLoginAttempt: (state, _action: LoginActionPayload) => {
            state.actions.login = true;
            state.error.login = '';
        },
        authLoginSuccess: (state, action: PayloadAction<string>) => {
            state.actions.login = false;
            state.authToken = action.payload;
        },
        authLoginFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.login = false;
            if (action.payload) {
                state.error.login = action.payload;
            }
        },

        getUserInfoAttempt: (state) => {
            state.actions.getUserInfo = true;
            state.error.getUserInfo = '';
        },
        getUserInfoSuccess: (state, action: PayloadAction<IGetUserInfo>) => {
            state.actions.getUserInfo = false;
            state.getUserInfo = action.payload;
        },
        getUserInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getUserInfo = false;
            if (action.payload) {
                state.error.getUserInfo = action.payload;
            }
        },

        getAdminDashboardAttempt: (state) => {
            state.actions.getAdminDashboard = true;
            state.error.getAdminDashboard = '';
        },
        getAdminDashboardSuccess: (state, action: PayloadAction<IAdminDashboard>) => {
            state.actions.getAdminDashboard = false;
            state.getAdminDashboard = action.payload;
        },
        getAdminDashboardFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAdminDashboard = false;
            if (action.payload) {
                state.error.getAdminDashboard = action.payload;
            }
        },
        getDailySettlementAttempt: (state, _action: GetDailySettlementActionPayload) => {
            state.actions.getDailySettlement = true;
            state.error.getDailySettlement = '';
        },
        getDailySettlementSuccess: (state, action: PayloadAction<IDailySettlement[]>) => {
            state.actions.getDailySettlement = false;
            state.dailySettlement = action.payload;
        },
        getDailySettlementFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getDailySettlement = false;
            if (action.payload) {
                state.error.getDailySettlement = action.payload;
            }
        },

        approveDailySettlementAttempt: (state, _action: ApproveDailySettlementActionPayload) => {
            state.actions.approveDailySettlement = true;
            state.error.approveDailySettlement = '';
        },
        approveDailySettlementSuccess: (state) => {
            state.actions.approveDailySettlement = false;
        },
        approveDailySettlementFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.approveDailySettlement = false;
            if (action.payload) {
                state.error.approveDailySettlement = action.payload;
            }
        },
        setIsSuperAdmin: (state, action: PayloadAction<boolean>) => {
            state.isSuperAdmin = action.payload;
        },
        setIsApproveSettlementModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isApproveSettlementModalOpen = action.payload;
        },

        authLogout: (state) => {
            state.actions.login = false;
            state.authToken = '';
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
