import { fork } from 'redux-saga/effects';

import PropertyGateway from 'api/Property';
import { RootSagaReturnType } from 'sagas/types';

import watchGetProperty from './getProperty';
import watchGetPropertyDetails from './getPropertyDetails';
import watchSetServicePackagePricing from './setServicePackagePricing';
import watchGetPendingPayments from './getPendingPayments';
import watchApproveRejectPayment from './approveRejectPayment';
import watchGetSearchedProperty from './getSearchedProperty';
import watchSetDescription from './setDescription';
import watchSetAmenities from './setAmenities';
import watchSetDetails from './setDetails';
import watchSetUser from './setPropertyUser';
import watchLocationProperty from './setLocation';
import watchGetSearchedOwners from './getSearchedOwners';
import watchGetPropertyUploadUrl from './getPropertyUploadUrl';
import watchUploadPropertyDocument from './uploadPropertyDocument';
import watchGetPropertyDeleteImage from './setDeleteImage';
import watchGetPropertyDetailsData from './getPropertyDetailsData';
import watchSetPropertyTenancyStatus from './setPropertyTenancyStatus';
import watchSetpropertySetDuplicate from './SetDuplicateProperty';
import watchSetPropertyPackagesPrice from './setPropertyPackagePrice';
import watchSetGoogleDrivePath from './setGoogleDrivePath';
import watchCreatePropertyGroup from './createPropertyGroup';
import watchGetPropertyGroup from './getPropertyGroup';
import watchDeletePropertyGroup from './deletePropertygroup';
import watchGetPropertyGroupDetails from './getPropertyGroupDetails';
import watchEditPropertyGroupDetails from './editPropertyGroupDetails';
import watchSetpropertyGroupDuplicate from './SetDuplicatePropertyGroup';
import watchCreateProperty from './createProperty';
import watchEditPropertyDetails from './editPropertyDetails';
import watchEditPropertyPackagesDetails from './editPropertyPackagesDetails';
import watchGetPropertiesReportExport from './getPropertiesReportExport';
import watchGroupPropertiesWithCount from './groupPropertiesWithCount';
import watchAddDesignRenovation from './addDesignRenovation';
import watchEditDesignRenovation from './editDesignRenovation';
import watchDeleteDesignRenovation from './deleteDesignRenovation';

export default (api: PropertyGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetProperty, api);
        yield fork(watchGetPropertyDetails, api);
        yield fork(watchSetServicePackagePricing, api);
        yield fork(watchGetPendingPayments, api);
        yield fork(watchApproveRejectPayment, api);
        yield fork(watchGetSearchedProperty, api);
        yield fork(watchSetDescription, api);
        yield fork(watchSetAmenities, api);
        yield fork(watchSetDetails, api);
        yield fork(watchSetUser, api);
        yield fork(watchLocationProperty, api);
        yield fork(watchGetSearchedOwners, api);
        yield fork(watchGetPropertyUploadUrl, api);
        yield fork(watchUploadPropertyDocument, api);
        yield fork(watchGetPropertyDeleteImage, api);
        yield fork(watchGetPropertyDetailsData, api);
        yield fork(watchSetPropertyTenancyStatus, api);
        yield fork(watchSetpropertySetDuplicate, api);
        yield fork(watchSetPropertyPackagesPrice, api);
        yield fork(watchSetGoogleDrivePath, api);
        yield fork(watchCreatePropertyGroup, api);
        yield fork(watchGetPropertyGroup, api);
        yield fork(watchDeletePropertyGroup, api);
        yield fork(watchGetPropertyGroupDetails, api);
        yield fork(watchEditPropertyGroupDetails, api);
        yield fork(watchSetpropertyGroupDuplicate, api);
        yield fork(watchCreateProperty, api);
        yield fork(watchEditPropertyDetails, api);
        yield fork(watchEditPropertyPackagesDetails, api);
        yield fork(watchGetPropertiesReportExport, api);
        yield fork(watchGroupPropertiesWithCount, api);
        yield fork(watchAddDesignRenovation, api);
        yield fork(watchEditDesignRenovation, api);
        yield fork(watchDeleteDesignRenovation, api);
    }

    return {
        rootSaga,
    };
};
