import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import {
    IApproveRejectPendingPayment,
    IServerPropertyReport,
    IServicePackagePricing,
    IServicePackagePriceIndividual,
    IGetProperty,
    IPropertyAmenities,
    IPropertyLocation,
    IPropertyOwners,
    IPropertyGroupDetails,
    IUpdatePackageParams,
    IPropertyWallet,
} from 'entities/property';

import {
    GetPendingPaymentResponse,
    IProperty,
    PaymentStatusEnum,
    PaymentTypeEnum,
    AdminGetPropertiesResponse,
    IPropertyDetails,
    Category,
    PackageCategory,
    IPropertyPackages,
    GetPropertyGroupResponse,
    PropertyFacilities,
    IExportPropertiesResponse,
    GroupPropertiesWithCountResponse,
    // GetPropertyGroup,
} from '@dm/types';

export interface GetPropertyParams {
    authToken: string;
    data: IGetProperty;
}

export interface GetPropertyDetailsParams {
    id: string;
    authToken: string;
}

export interface SetPackagePricingParams {
    authToken: string;
    data: IServicePackagePricing;
}

export interface GetPendingPaymentsParams {
    authToken: string;
    data: {
        index: number;
        dateTo: string;
        dateFrom: string;
        type: PaymentTypeEnum;
        status: PaymentStatusEnum;
    }
}

export interface ApproveRejectPaymentParams {
    authToken: string;
    data: IApproveRejectPendingPayment;
}

export interface GetSearchedPropertyParams {
    authToken: string;
    search: string;
}

export interface PropertySetDescriptionParams {
    id: string;
    description: string;
    authToken: string;
}

export interface PropertySetPropertyAmenitiesParams {
    data: IPropertyAmenities,
    authToken: string;
}

export interface PropertyDetailsParams {
    data: IPropertyDetails,
    authToken: string;
}

export interface PropertyUserParams {
    propertyId: string;
    userId: string;
    authToken: string;
}

export interface PropertySetPropertyLocationParams {
    data: IPropertyLocation,
    authToken: string;
}

export interface PropertyCreatePropertyParams {
    name: string;
    authToken: string;
}

export interface GetPropertyUploadUrlParams {
    authToken: string;
    category: Category;
    id: string;
    name: string;
    extension: string;
}

export interface UploadPropertyDocumentParams {
    url: string;
    doc: FormData;
}

export interface ImageDeleteParams {
    authToken: string;
    category: number;
    name: string;
    id: string;
    fileName: string;
}

export interface GetPropertyDetailsDataParams {
    authToken: string;
    id: string;
}

export interface SetPropertyTenancyStatusParams {
    authToken: string;
    propertyId: string;
    tenancyStatus: number;
}

export interface SetPropertyDuplicateParams {
    authToken: string;
    propertyId: string;
}

export interface UpdatePropertyPackagesPriceParams {
    authToken: string;
    data: IServicePackagePriceIndividual;
}

export interface SetGoogleDrivePathParams {
    authToken: string;
    propertyId: string;
    packageType: PackageCategory;
    googleDrivePath: string;
}

export interface CreatePropertyGroupParams {
    authToken: string;
    name: string;
}

export interface GetPropertyGroupParams {
    authToken: string;
    index?: number;
    propertyGroupId?: string;
    propertyGroupName?: string;
}

export interface EditPropertyGroupDetails {
    authToken: string;
    propertyGroupId: string;
    details?: {
        type: number;
        titleType: number;
        buildUpSize: number;
        landTitle: number;
        facingDirection: string;
        storey: number;
        builtUpDimension: string;
        buildUpPricePerSqft: number;
    },
    location?: {
        long: number;
        lat: number;
        address1: string;
        address2: string;
        postcode: string;
        city: string;
        state: string;
        country: string;
    },
    facilities?: PropertyFacilities[];
    description?:string;
    images?: [];
}

export interface DeletePropertyGroupParams{
    authToken: string;
    propertyGroupId: string;
}

export interface CreatePropertyParams {
    authToken: string;
    name: string;
}

export interface EditPropertyDetails {
    authToken: string;
    propertyId: string;
    details?: {
        type: number;
        titleType: number;
        buildUpSize: number;
        occupancy: string;
        landTitle: number;
        tenure: number;
        furnishing: number;
        facingDirection: string;
        preferredTenant: number[];
        unitType: string;
        storey: number;
        builtUpDimension: string;
        buildUpPricePerSqft: number;
        tnbAccNo?: string;
        parcelRentAccNo?: string;
        assTaxAccNo?: string;
        petGasAccNo?: string;
        parkingLotNo?: string;
        spaPrice?: string;
        nettPrice?: string;
},
    location?: {
        long: number;
        lat: number;
        address1: string;
        address2: string;
        postcode: string;
        city: string;
        state: string;
        country: string;
    },
    amenities?: {
        facilities: PropertyFacilities[],
        furnishing: PropertyFacilities[],
    },
    description?:string;
    unitNo?: string;
}

export type EditPropertyPackagesDetailsParams = IUpdatePackageParams & {
    authToken: string;
};

export type GetPropertiesReportExportParams = {
    authToken: string;
    filter: Omit<IGetProperty, 'index'>;
}

export type AddDesignRenovationPackagesParams = {
    authToken: string;
    propertyId: string;
    packageName: string;
    price: number;
    cost: number;
    profit: number;
    packageType: number;
    startDate: string;
    endDate?: string;
    paidPrice?: number;
}

export type EditDesignRenovationPackagesParams = AddDesignRenovationPackagesParams & {
    id: string
}

export type DeleteDesignRenovationPackagesParams = {
    authToken: string;
    id: string;
    packageType: number;
}

export abstract class IPropertyGateway extends Gateway {
    abstract getProperty(params: GetPropertyParams): GatewayResponse<IServerPropertyReport>;

    abstract getPropertyDetails(params: GetPropertyDetailsParams): GatewayResponse<IProperty>;

    abstract setPackagePricing(params: SetPackagePricingParams): GatewayResponse<null>;

    abstract getPendingPayments(params: GetPendingPaymentsParams): GatewayResponse<GetPendingPaymentResponse>;

    abstract approveRejectPayment(params: ApproveRejectPaymentParams): GatewayResponse<null>;

    abstract getSearchedProperty(params: GetSearchedPropertyParams): GatewayResponse<AdminGetPropertiesResponse[]>;

    abstract setDescription(params: PropertySetDescriptionParams): GatewayResponse<string>;

    abstract setPropertyAmenities(params: PropertySetPropertyAmenitiesParams): GatewayResponse<string | null>;

    abstract setPropertyDetails(params: PropertyDetailsParams): GatewayResponse<string | null>;

    abstract setPropertyUser(params: PropertyUserParams): GatewayResponse<string>;

    abstract setPropertyLocation(params: PropertySetPropertyLocationParams): GatewayResponse<string>;

    abstract setCreateProperty(params: PropertyCreatePropertyParams): GatewayResponse<{id : string}>;

    abstract getSearchedOwner(params: GetSearchedPropertyParams): GatewayResponse<IPropertyOwners | null>;

    abstract getPropertyUploadUrl(params: GetPropertyUploadUrlParams): GatewayResponse<string>;

    abstract uploadPropertyImage(params: UploadPropertyDocumentParams): GatewayResponse<null>;

    abstract setPropertyDeleteImage(params: ImageDeleteParams): GatewayResponse<string>;

    abstract getPropertyDetailsData(params: GetPropertyDetailsDataParams): GatewayResponse<IProperty | null>;

    abstract setPropertyTenancyStatus(params: SetPropertyTenancyStatusParams): GatewayResponse<string | null>;

    abstract setPropertyDuplicate(params: SetPropertyDuplicateParams): GatewayResponse<string | null>;

    abstract setPropertyPackagesPrice(params: UpdatePropertyPackagesPriceParams): GatewayResponse<string | null>;

    abstract setGoogleDrivePath(params: SetGoogleDrivePathParams): GatewayResponse< IProperty | null>;

    abstract createPropertyGroup(params: CreatePropertyGroupParams): GatewayResponse<string | null>;

    abstract getPropertyGroup(params: GetPropertyGroupParams): GatewayResponse<GetPropertyGroupResponse>;

    abstract getPropertyGroupDetails(params:GetPropertyGroupParams): GatewayResponse<IPropertyGroupDetails>;

    abstract editPropertyGroupDetails(params:EditPropertyGroupDetails): GatewayResponse<string | null>;

    abstract setPropertyGroupDuplicate(params: DeletePropertyGroupParams): GatewayResponse<string | null>;

    abstract deletePropertyGroup(params: DeletePropertyGroupParams): GatewayResponse<string | null>;

    abstract createProperty(params: CreatePropertyParams): GatewayResponse<string | null>;

    abstract editPropertyDetails(params:EditPropertyDetails): GatewayResponse<string | null>;

    abstract editPropertyPackagesDetails(params:EditPropertyPackagesDetailsParams): GatewayResponse<string | null>;

    abstract getPropertiesReportExport(params: GetPropertiesReportExportParams): GatewayResponse<IExportPropertiesResponse[]>;

    abstract groupPropertiesWithCount(params: { authToken: string}): GatewayResponse<GroupPropertiesWithCountResponse[]>;

    abstract addDesignRenovationPackages(params: AddDesignRenovationPackagesParams): GatewayResponse<string | null>;

    abstract editDesignRenovationPackages(params: EditDesignRenovationPackagesParams): GatewayResponse<string | null>;

    abstract deleteDesignRenovationPackages(params: DeleteDesignRenovationPackagesParams): GatewayResponse<string | null>;
}
