import { Button, Colors } from '@dm/bigfish';
import { PackageCategory } from '@dm/types';
import dayjs from 'dayjs';
import { IPropertyDesignRenovationDetails } from 'entities/property';
import React, { FC, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

type ComponentProps = {
    designRenovationDetails: IPropertyDesignRenovationDetails,
    setDesignRenovationDetails: (designRenovationDetails: IPropertyDesignRenovationDetails) => void,
    removeDesignRenovationDetails: () => void
}

const DesignRenoPackagesInput: FC<ComponentProps> = ({
    designRenovationDetails,
    setDesignRenovationDetails,
    removeDesignRenovationDetails,
}) => {
    const dispatch = useAppDispatch();

    const addDesignRenovationAttempt = useAppSelector((state) => state.property.actions.addDesignRenovation);
    const editDesignRenovationAttempt = useAppSelector((state) => state.property.actions.editDesignRenovation);
    const deleteDesignRenovationAttempt = useAppSelector((state) => state.property.actions.deleteDesignRenovation);

    // const [basePrice, setBasePrice] = useState(designRenovationDetails.price);
    // const [baseCost, setBaseCost] = useState(designRenovationDetails.cost);
    // const [baseProfit, setBaseProfit] = useState(designRenovationDetails.profit);

    // useEffect(() => {
    //     setBasePrice(designRenovationDetails.price);
    //     setBaseCost(designRenovationDetails.cost);
    //     setBaseProfit(designRenovationDetails.profit);
    // }, []);

    useEffect(() => {
        const totalCostAndProfit = designRenovationDetails.cost + designRenovationDetails.profit;
        if (totalCostAndProfit !== designRenovationDetails.price) {
            const calculateNewProfit = designRenovationDetails.price - designRenovationDetails.cost;
            setDesignRenovationDetails({ ...designRenovationDetails, profit: calculateNewProfit });
        }
    }, [designRenovationDetails.price, designRenovationDetails.cost]);

    const confirmClickHandler = () => {
        if (designRenovationDetails?._id) {
            dispatch(Actions.editDesignRenovationAttempt({ ...designRenovationDetails, id: designRenovationDetails._id, packageType: PackageCategory.DesignRenovation }));
            return;
        }
        const dataToSubmit = {
            ...designRenovationDetails,
            startDate: dayjs(designRenovationDetails.startDate).toISOString(),
            endDate: designRenovationDetails.endDate ? dayjs(designRenovationDetails.endDate).toISOString() : undefined,
            packageType: PackageCategory.DesignRenovation,
        };

        if (!dataToSubmit.packageName
            || !dataToSubmit.price
            || !dataToSubmit.cost
            || !dataToSubmit.startDate
        ) {
            toast.error('Please fill all required fields');
            return;
        }

        if (
            dataToSubmit.packageName
            && dataToSubmit.price
            && dataToSubmit.startDate
        ) {
            dispatch(Actions.addDesignRenovationAttempt(dataToSubmit));
        }
    };

    const renderPackageName = () => {
        return (
            <input
                value={designRenovationDetails.packageName ?? ''}
                onChange={e => setDesignRenovationDetails({ ...designRenovationDetails, packageName: e.target.value })}
                style={{
                    padding: '5px 10px',
                }}
            />
        );
    };
    const renderPackagePrice = () => {
        return (
            <input
                value={designRenovationDetails.price ?? 0}
                onChange={e => setDesignRenovationDetails({ ...designRenovationDetails, price: Number(e.target.value) })}
                style={{
                    padding: '5px 10px',
                }}
            />
        );
    };
    const renderPackageCost = () => {
        return (
            <input
                value={designRenovationDetails.cost ?? 0}
                onChange={e => setDesignRenovationDetails({ ...designRenovationDetails, cost: Number(e.target.value) })}
                style={{
                    padding: '5px 10px',
                }}
            />
        );
    };
    const renderPackageProfit = () => {
        return (
            <input
                disabled
                value={designRenovationDetails.profit ?? 0}
                onChange={e => setDesignRenovationDetails({ ...designRenovationDetails, profit: Number(e.target.value) })}
                style={{
                    padding: '5px 10px',
                }}
            />
        );
    };
    const renderPackagePaidPrice = () => {
        return (
            <input
                value={designRenovationDetails.paidPrice ?? 0}
                onChange={e => setDesignRenovationDetails({ ...designRenovationDetails, paidPrice: Number(e.target.value) })}
                style={{
                    padding: '5px 10px',
                }}
            />
        );
    };
    const renderPackageStartDate = () => {
        return (
            <DateTimePicker
                onChange={(newDate: Date) => setDesignRenovationDetails({ ...designRenovationDetails, startDate: dayjs(newDate).toISOString() })}
                value={!designRenovationDetails.startDate ? 'Select Date' : dayjs(designRenovationDetails.startDate).format('DD-MM-YYYY')}
                selected={new Date(designRenovationDetails.startDate) ?? undefined}
                // dateFormat='MMMM d, yyyy HH:mm'
                shouldCloseOnSelect
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
            />
        );
    };
    const renderPackageEndDate = () => {
        return (
            <DateTimePicker
                onChange={(newDate: Date) => setDesignRenovationDetails({ ...designRenovationDetails, endDate: dayjs(newDate).toISOString() })}
                value={!designRenovationDetails.endDate ? 'Select Date' : dayjs(designRenovationDetails.endDate).format('DD-MM-YYYY')}
                selected={designRenovationDetails.endDate ? new Date(designRenovationDetails.endDate) : undefined}
                // dateFormat='MMMM d, yyyy HH:mm'
                shouldCloseOnSelect
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
            />
        );
    };
    return (
        <Container>
            <EachPackageContainer>
                <InputGrid>
                    <div>
                        Package Name
                        <span style={{ color: 'red' }}>*</span>
                        :
                    </div>
                    {renderPackageName()}
                </InputGrid>
                <InputGrid>
                    <div>
                        Package Price(RM)
                        <span style={{ color: 'red' }}>*</span>
                        :
                    </div>
                    {renderPackagePrice()}
                </InputGrid>
                <InputGrid>
                    <div>
                        Package Cost(RM)
                        <span style={{ color: 'red' }}>*</span>
                        :
                    </div>
                    {renderPackageCost()}
                </InputGrid>
                <InputGrid>
                    <div>
                        Package Profit(RM):
                    </div>
                    {renderPackageProfit()}
                </InputGrid>
                <InputGrid>
                    <div>
                        Paid Price(RM):
                    </div>
                    {renderPackagePaidPrice()}
                </InputGrid>
                <InputGrid>
                    <div>
                        Package Start Date
                        <span style={{ color: 'red' }}>*</span>
                        :
                    </div>
                    {renderPackageStartDate()}
                </InputGrid>
                <InputGrid>
                    <div>
                        Package End Date:
                    </div>
                    {renderPackageEndDate()}
                </InputGrid>
            </EachPackageContainer>
            <ButtonContainer>
                <div>
                    <Button
                        label='Remove Package'
                        style={{
                            marginRight: '10px',
                            backgroundColor: Colors.danger,
                            color: Colors.white,
                            border: `1px solid ${Colors.primary}`,
                            width: '200px',
                        }}
                        onClick={() => removeDesignRenovationDetails()}
                        loading={deleteDesignRenovationAttempt}
                        disabled={deleteDesignRenovationAttempt || addDesignRenovationAttempt || editDesignRenovationAttempt}
                    />
                    <Button
                        label={designRenovationDetails._id ? 'Update Package' : 'Create Package'}
                        style={{ width: '200px' }}
                        onClick={() => confirmClickHandler()}
                        loading={addDesignRenovationAttempt || editDesignRenovationAttempt}
                        disabled={addDesignRenovationAttempt || editDesignRenovationAttempt}
                    />
                </div>
            </ButtonContainer>
        </Container>
    );
};

const EachPackageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid ${Colors.primaryLight};
    border-radius: 1rem;

    padding: 20px;
`;

const DateTimePicker = styled(ReactDatePicker)`
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0.375rem 0.75rem;
    /* font-size: 1rem; */
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${Colors.black};
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    &:focus, &:active{
        border-color: rgba(61, 39, 78, 1);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(61, 39, 78, 0.6);
        outline: 0 none;
    }
    &:disabled{
        background-color: ${Colors.grey};
        cursor: not-allowed;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${Colors.primary};
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${Colors.primary};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${Colors.primary};
    }
`;

const InputGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-gap: 20px;

    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: flex-start;
`;

export default DesignRenoPackagesInput;
