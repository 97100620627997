import { select, call, put, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';
import { ApproveDailySettlementActionPayload } from 'redux/slices/auth/types';
import { toast } from 'react-toastify';

export default function* watchApproveDailySettlement(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/approveDailySettlementAttempt', handleApproveDailySettlement, api);
}

function* handleApproveDailySettlement(api: AuthGateway, data: ApproveDailySettlementActionPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { id, status } = data.payload;
    const response = yield* call([api, api.approveDailySettlement], {
        authToken,
        id,
        status,
    });

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            toast.error('Something went wrong. Please try again.');
            yield put(Actions.approveDailySettlementFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.approveDailySettlementSuccess());
        yield put(Actions.getDailySettlementAttempt({ index: 1 }));
        yield put(Actions.setIsApproveSettlementModalOpen(false));
        toast.success('Daily settlement approved!');
    } else {
        yield put(Actions.approveDailySettlementFailure(response.message));
        toast.error(response.message);
    }
}
