import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPropertyGroupDetails, IPropertyOwners, IServerPropertyReport } from 'entities/property';

import { AdminGetPropertiesResponse, Category, GetPropertyGroupResponse, IProperty, PropertyImage, GroupPropertiesWithCountResponse } from '@dm/types';
import { PropertyReduxState, GetAllActionPayload, GetDetailsActionPayload, SetServicePackageActionPayload, SetDescriptionActionPayload, PropertyAmenitiesPayload, SetDetails1ActionPayload, SetUserActionPayload, SetLocationPayload, SetCreatePropertyPayload, CoordinatesAddress1, CoordinatesAddress2, CoordinatesPoscode, CoordinatesState, CoordinatesCountry, CoordinatesCity, Coordinates, GetPropertyUploadUrlActionPayload, SetImageDeleteActionPayload, UploadPropertyImageActionPayload, GetPropertyDetailsDataActionPayload, SetTenancyStatusDataActionPayload, SetDuplicatePropertyActionPayload, SetPropertyPackagePricePayload, SetGoogleDrivePathPayload, GetPropertyGroupPayload, CreatePropertyGroupPayload, DeletePropertyGroupPayload, GetPropertyGroupDetailsPayload, EditPropertyGroupDetailsPayload, SetDuplicatePropertyGroupActionPayload, EditPropertyDetailsPayload, CreatePropertyPayload, EditPropertyPackagesDetailsPayload, GetPropertiesReportExportPayload, AddDesignRenovationPayload, EditDesignRenovationPayload, DeleteDesignRenovationPayload } from './types';

const initialState: PropertyReduxState = {
    actions: {
        getAll: false,
        getDetails: false,
        setServicePackage: false,
        getSearchedProperty: false,
        setDescription: false,
        setPropertyAmenities: false,
        propertyDetails: false,
        propertyUser: false,
        setLocationProperty: false,
        setCreateProperty: false,
        getSearchedOwner: false,
        getPropertyUploadUrl: false,
        uploadPropertyDocument: false,
        setDeleteImage: false,
        getPropertPropertyDetails: false,
        setPropertyTenancyStatus: false,
        setDuplicateProperty: false,
        setPropertyPackagesPrice: false,
        setGoogleDrivePath: false,
        createPropertyGroup: false,
        getPropertyGroup: false,
        deletePropertyGroup: false,
        getPropertyGroupDetails: false,
        editPropertyGroupDetails: false,
        setDuplicatePropertyGroup: false,
        createProperty: false,
        editPropertyDetails: false,
        editPropertyPackages: false,
        getPropertiesReportExport: false,
        groupPropertiesWithCount: false,
        addDesignRenovation: false,
        editDesignRenovation: false,
        deleteDesignRenovation: false,
    },
    propertyReport: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    propertyDetails: null,
    groupPropertiesWithCount: null,
    searchedProperty: [],
    isServicePackageModalOpen: false,
    isUpdatePackagePriceModalOpen: false,
    isSetGoogleDrivePathModalOpen: false,
    isSetMilestonesModalOpen: false,
    isRejectPropertyModalOpen: false,
    isAddDesignRenovationModalOpen: false,
    propertyDescription: '',
    setPropertyAmenitiesData: '',
    setPropertyDetails: '',
    propertySetUserData: '',
    setLocationData: '',
    createPropertyModal: false,
    setCreatePropertyId: '',
    searchedOwner: null,
    isPropertyDataLoaded: false,

    coordinates: {
        latitude: '',
        longitude: '',
    },

    coordinatesaddress1: {
        address1: '',
    },

    coordinatesaddress2: {
        address2: '',
    },

    coordinatesposcode: {
        poscode: '',
    },

    coordinatesstate: {
        state: '',
    },

    coordinatescountry: {
        country: '',
    },

    coordinatescity: {
        city: '',
    },

    PropertyImageUploadURL: '',
    PropertyImageUploadComplete: false,
    deletedImage: '',
    propertyDetailsData: null,
    LocationLatitudeFirstLoad: 0,
    LocationLongitudeFirstLoad: 0,
    propertyLocationSubmitted: false,
    tenancyStatusData: '',
    isVacancyStatusPropertyModalOpen: false,
    propertyDuplicateProperty: '',
    searchTransaction: '',
    setPropertyPackagesPrice: '',
    googleDrivePath: {
        _id: '',
        name: '',
        userId: '',
        status: 0,
        images: [],
    },
    propertyGroupId: '',
    propertyGroup: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    propertyGroupDetails: {
        data: {
            terms: {
                accepted: false,
            },
            location: {
                long: 0,
                lat: 0,
                address1: '',
                address2: '',
                postcode: '',
                city: '',
                state: '',
                country: '',
            },
            details: {
                type: 0,
                titleType: 0,
                buildUpSize: 0,
                landTitle: 0,
                facingDirection: '',
                storey: 0,
                builtUpDimension: '',
                buildUpPricePerSqft: 0,
            },
            amenities: {
                facilities: [],
            },
            _id: '',
            description: '',
            images: [],
            name: '',
            status: 0,
            tenancyStatus: 0,
        },
    },
    isPropertyGroupList: false,
    propertyImagesCollapsible: false,
    propertyImagesSubmitted: false,
    isMapLocationError: false,
    propertyId: '',
    editPropertyPackages: '',
    propertyImagesUploaded: [],
    propertyImagesDeleted: [],
    error: {
        getAll: '',
        getDetails: '',
        setServicePackage: '',
        getSearchedProperty: '',
        setDescription: '',
        setPropertyAmenities: '',
        propertyDetails: '',
        propertyUser: '',
        setLocationProperty: '',
        setCreateProperty: '',
        getSearchedOwner: '',
        getPropertyUploadUrl: '',
        uploadPropertyDocument: '',
        setDeleteImage: '',
        getPropertPropertyDetails: '',
        setPropertyTenancyStatus: '',
        setDuplicateProperty: '',
        setPropertyPackagesPrice: '',
        setGoogleDrivePath: '',
        createPropertyGroup: '',
        getPropertyGroup: '',
        deletePropertyGroup: '',
        getPropertyGroupDetails: '',
        editPropertyGroupDetails: '',
        setDuplicatePropertyGroup: '',
        createProperty: '',
        editPropertyDetails: '',
        editPropertyPackages: '',
        groupPropertiesWithCount: '',
        addDesignRenovation: '',
        editDesignRenovation: '',
        deleteDesignRenovation: '',
    },
};

const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        propertyGetAllAttempt: (state, _action: GetAllActionPayload) => {
            state.actions.getAll = true;
            state.error.getAll = '';
        },
        propertyGetAllSuccess: (state, action: PayloadAction<IServerPropertyReport>) => {
            state.actions.getAll = false;

            if (action.payload) {
                state.propertyReport = action.payload;
            }
        },
        propertyGetAllFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAll = false;
            if (action.payload) {
                state.error.getAll = action.payload;
            }
        },
        clearPropertyReport: (state) => {
            state.propertyReport = {
                index: 0,
                maxIndex: 0,
                data: [],
            };
        },
        propertyGetDetailsAttempt: (state, _action: GetDetailsActionPayload) => {
            state.actions.getDetails = true;
            state.error.getDetails = '';
        },
        propertyGetDetailsSuccess: (state, action: PayloadAction<IProperty>) => {
            state.actions.getDetails = false;
            state.propertyDetails = action.payload;
        },
        propertyGetDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getDetails = false;
            if (action.payload) {
                state.error.getDetails = action.payload;
            }
        },
        clearPropertyDetails: (state) => {
            state.propertyDetails = null;
        },

        setServicePackageAttempt: (state, _action: SetServicePackageActionPayload) => {
            state.actions.setServicePackage = true;
            state.error.setServicePackage = '';
        },
        setServicePackageSuccess: (state) => {
            state.actions.setServicePackage = false;
            state.error.setServicePackage = '';
        },
        setServicePackageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setServicePackage = false;
            if (action.payload) {
                state.error.setServicePackage = action.payload;
            }
        },

        setServicePackageModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isServicePackageModalOpen = action.payload;
        },

        setUpdatePackagePriceModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isUpdatePackagePriceModalOpen = action.payload;
        },
        setGoogleDrivePathModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isSetGoogleDrivePathModalOpen = action.payload;
        },
        setMilestonesModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isSetMilestonesModalOpen = action.payload;
        },
        setRejectPropertyModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isRejectPropertyModalOpen = action.payload;
        },
        setAddDesignRenovationModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isAddDesignRenovationModalOpen = action.payload;
        },
        getSearchedPropertyAttempt: (state, _action: PayloadAction<string>) => {
            state.actions.getSearchedProperty = true;
            state.error.getSearchedProperty = '';
        },
        getSearchedPropertySuccess: (state, action: PayloadAction<AdminGetPropertiesResponse[]>) => {
            state.actions.getSearchedProperty = false;
            state.searchedProperty = action.payload;
        },
        getSearchedPropertyFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getSearchedProperty = false;
            if (action.payload) {
                state.error.getSearchedProperty = action.payload;
            }
        },
        clearSearchedProperty: (state) => {
            state.searchedProperty = [];
        },
        propertySetDescriptionAttempt: (state, _action: SetDescriptionActionPayload) => {
            state.actions.setDescription = true;
            state.error.setDescription = '';
        },
        propertySetDescriptionSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setDescription = false;
            state.propertyDescription = action.payload;
        },
        propertySetDescriptionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setDescription = false;
            if (action.payload) {
                state.error.setDescription = action.payload;
            }
        },
        propertySetPropertyAmenitiesAttempt: (state, _action: PropertyAmenitiesPayload) => {
            state.actions.setPropertyAmenities = true;
            state.error.setPropertyAmenities = '';
        },
        propertySetPropertyAmenitiesSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setPropertyAmenities = false;
            state.setPropertyAmenitiesData = action.payload;
        },
        propertySetPropertyAmenitiesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPropertyAmenities = false;
            if (action.payload) {
                state.error.setPropertyAmenities = action.payload;
            }
        },
        propertySetPropertyDetailsAttempt: (state, _action: SetDetails1ActionPayload) => {
            state.actions.propertyDetails = true;
            state.error.propertyDetails = '';
        },
        propertySetPropertyDetailsSuccess: (state, action: PayloadAction<string>) => {
            state.actions.propertyDetails = false;
            state.setPropertyDetails = action.payload;
        },
        propertySetPropertyDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.propertyDetails = false;
            if (action.payload) {
                state.error.propertyDetails = action.payload;
            }
        },
        propertySetUserAttempt: (state, _action: SetUserActionPayload) => {
            state.actions.propertyUser = true;
            state.error.propertyUser = '';
        },
        propertySetUserSuccess: (state, action: PayloadAction<string>) => {
            state.actions.propertyUser = false;
            state.propertySetUserData = action.payload;
        },
        propertySetUserFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.propertyUser = false;
            if (action.payload) {
                state.error.propertyUser = action.payload;
            }
        },
        propertySetLocationAttempt: (state, _action: SetLocationPayload) => {
            state.actions.setLocationProperty = true;
            state.error.setLocationProperty = '';
        },
        propertySetLocationSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setLocationProperty = false;
            state.setLocationData = action.payload;
        },
        propertySetLocationFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setLocationProperty = false;
            if (action.payload) {
                state.error.setLocationProperty = action.payload;
            }
        },

        propertyCreateNewDraftAttempt: (state, _action: SetCreatePropertyPayload) => {
            state.actions.setCreateProperty = true;
            state.error.setCreateProperty = '';
        },
        propertyCreateNewDraftSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setCreateProperty = false;
            state.setCreatePropertyId = action.payload;
        },
        propertyCreateNewDraftFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setCreateProperty = false;
            if (action.payload) {
                state.error.setCreateProperty = action.payload;
            }
        },

        propertyCreatePropertyModal: (state, action: PayloadAction<boolean>) => {
            state.createPropertyModal = action.payload;
        },

        getSearchedOwnerAttempt: (state, _action: PayloadAction<string>) => {
            state.actions.getSearchedOwner = true;
            state.error.getSearchedOwner = '';
        },
        getSearchedOwnerSuccess: (state, action: PayloadAction<IPropertyOwners>) => {
            state.actions.getSearchedOwner = false;
            state.searchedOwner = action.payload;
        },
        getSearchedOwnerFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getSearchedOwner = false;
            if (action.payload) {
                state.error.getSearchedOwner = action.payload;
            }
        },

        propertySetCoordinates: (state, action: PayloadAction<Coordinates>) => {
            state.coordinates = action.payload;
        },

        propertySetCoordinatesAddress1: (state, action: PayloadAction<CoordinatesAddress1>) => {
            state.coordinatesaddress1 = action.payload;
        },

        propertySetCoordinatesAddress2: (state, action: PayloadAction<CoordinatesAddress2>) => {
            state.coordinatesaddress2 = action.payload;
        },

        propertySetCoordinatesPoscode: (state, action: PayloadAction<CoordinatesPoscode>) => {
            state.coordinatesposcode = action.payload;
        },

        propertySetCoordinatesState: (state, action: PayloadAction<CoordinatesState>) => {
            state.coordinatesstate = action.payload;
        },

        propertySetCoordinatesCountry: (state, action: PayloadAction<CoordinatesCountry>) => {
            state.coordinatescountry = action.payload;
        },

        propertySetCoordinatesCity: (state, action: PayloadAction<CoordinatesCity>) => {
            state.coordinatescity = action.payload;
        },

        getPropertyUploadUrlAttempt: (state, _action: GetPropertyUploadUrlActionPayload) => {
            state.actions.getPropertyUploadUrl = true;
            state.error.getPropertyUploadUrl = '';
        },

        getPropertyUploadUrlSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getPropertyUploadUrl = false;
            state.error.getPropertyUploadUrl = '';

            state.PropertyImageUploadURL = action.payload;
        },
        getPropertyUploadUrlFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyUploadUrl = false;
            if (action.payload) {
                state.error.getPropertyUploadUrl = action.payload;
            }
        },

        uploadPropertyImagesAttempt: (state, _action: UploadPropertyImageActionPayload) => {
            state.actions.uploadPropertyDocument = true;
            state.error.uploadPropertyDocument = '';
        },
        uploadPropertyImagesSuccess: (state, action: PayloadAction<PropertyImage & { category: number }>) => {
            state.actions.uploadPropertyDocument = false;
            state.error.uploadPropertyDocument = '';

            if (action.payload) {
                if (action.payload.category === Category.Property) {
                    if (state.propertyDetailsData?.images && state.propertyDetailsData?.images.length > 0) {
                        const index = state.propertyDetailsData?.images.findIndex(
                            (item) => item.name === action.payload.name,
                        );
                        if (index !== -1) {
                            state.propertyDetailsData.images[index].url = state.propertyDetailsData.images[index].url.concat(action.payload.url);
                        } else {
                            state.propertyDetailsData.images.push(action.payload);
                        }
                    } else {
                        state.propertyDetailsData?.images.push(action.payload);
                    }
                }

                if (action.payload.category === Category.PropertyGroup) {
                    if (state.propertyGroupDetails?.data.images && state.propertyGroupDetails?.data.images.length > 0) {
                        const index = state.propertyGroupDetails?.data.images.findIndex(
                            (item) => item.name === action.payload.name,
                        );
                        if (index !== -1) {
                            state.propertyGroupDetails.data.images[index].url = state.propertyGroupDetails.data.images[index].url.concat(action.payload.url);
                        } else {
                            state.propertyGroupDetails.data.images.push(action.payload);
                        }
                    } else {
                        state.propertyGroupDetails?.data.images.push(action.payload);
                    }
                }
            }
        },

        setPropertyImageUploadComplete: (state, action: PayloadAction<boolean>) => {
            state.PropertyImageUploadComplete = action.payload;
        },

        propertySetDeleteImageAttempt: (state, _action: SetImageDeleteActionPayload) => {
            state.actions.setDeleteImage = true;
            state.error.setDeleteImage = '';
        },
        propertySetDeleteImageSuccess: (state, action: PayloadAction<PropertyImage & { category: number }>) => {
            state.actions.setDeleteImage = false;

            if (action.payload) {
                if (action.payload.category === Category.Property) {
                    if (state.propertyDetailsData?.images && state.propertyDetailsData?.images.length > 0) {
                        const index = state.propertyDetailsData?.images.findIndex(
                            (item) => item.name === action.payload.name,
                        );
                        if (index !== -1) {
                            if (state.propertyDetailsData.images[index].url.length === 1) {
                                state.propertyDetailsData.images = state.propertyDetailsData.images.filter((item) => item.name !== action.payload.name);
                            } else {
                                state.propertyDetailsData.images[index].url = state.propertyDetailsData.images[index].url.filter((url) => url !== action.payload.url[0]);
                            }
                        } else {
                            state.propertyDetailsData.images.push(action.payload);
                        }
                    } else {
                        state.propertyDetailsData?.images.push(action.payload);
                    }
                }

                if (action.payload.category === Category.PropertyGroup) {
                    if (state.propertyGroupDetails?.data.images && state.propertyGroupDetails?.data.images.length > 0) {
                        const index = state.propertyGroupDetails?.data.images.findIndex(
                            (item) => item.name === action.payload.name,
                        );
                        if (index !== -1) {
                            if (state.propertyGroupDetails?.data.images[index].url.length === 1) {
                                state.propertyGroupDetails.data.images = state.propertyGroupDetails.data.images.filter((item) => item.name !== action.payload.name);
                            } else {
                                state.propertyGroupDetails.data.images[index].url = state.propertyGroupDetails.data.images[index].url.filter((url) => url !== action.payload.url[0]);
                            }
                        } else {
                            state.propertyGroupDetails?.data.images.push(action.payload);
                        }
                    } else {
                        state.propertyGroupDetails?.data.images.push(action.payload);
                    }
                }
            }
        },
        propertySetDeleteImageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setDeleteImage = false;
            if (action.payload) {
                state.error.setDeleteImage = action.payload;
            }
        },

        getPropertPropertyDetailsAttempt: (state, _action: GetPropertyDetailsDataActionPayload) => {
            state.actions.getPropertPropertyDetails = true;
            state.error.getPropertPropertyDetails = '';
        },

        getPropertPropertyDetailsSuccess: (state, action: PayloadAction<IProperty | null>) => {
            state.actions.getPropertPropertyDetails = false;
            state.error.getPropertPropertyDetails = '';
            state.isPropertyDataLoaded = true;

            if (action.payload) {
                state.propertyDetailsData = action.payload;
            }
        },
        getPropertPropertyDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertPropertyDetails = false;
            if (action.payload) {
                state.error.getPropertPropertyDetails = action.payload;
            }
        },

        clearPropertyDetailsData: (state) => {
            state.propertyDetailsData = null;
            state.isPropertyDataLoaded = false;
        },

        setIsPropertyDataLoaded: (state, action: PayloadAction<boolean>) => {
            state.isPropertyDataLoaded = action.payload;
        },

        propertySetLocationLatitudeFirstLoad: (state, action: PayloadAction<number>) => {
            state.LocationLatitudeFirstLoad = action.payload;
        },
        propertySetLocationLongitudeFirstLoad: (state, action: PayloadAction<number>) => {
            state.LocationLongitudeFirstLoad = action.payload;
        },
        setPropertyLocationSubmitted: (state, action: PayloadAction<boolean>) => {
            state.propertyLocationSubmitted = action.payload;
        },

        setPropertyTenancyStatusAttempt: (state, _action: SetTenancyStatusDataActionPayload) => {
            state.actions.setPropertyTenancyStatus = true;
            state.error.setPropertyTenancyStatus = '';
        },

        setPropertyTenancyStatusSuccess: (state, action: PayloadAction<string | null>) => {
            state.actions.setPropertyTenancyStatus = false;
            state.error.setPropertyTenancyStatus = '';

            state.tenancyStatusData = action.payload;
        },
        setPropertyTenancyStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPropertyTenancyStatus = false;
            if (action.payload) {
                state.error.setPropertyTenancyStatus = action.payload;
            }
        },

        setVacancyStatusPropertyModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isVacancyStatusPropertyModalOpen = action.payload;
        },

        propertySetDuplicatePropertyAttempt: (state, _action: SetDuplicatePropertyActionPayload) => {
            state.actions.setDuplicateProperty = true;
            state.error.setDuplicateProperty = '';
        },
        propertySetDuplicatePropertySuccess: (state, action: PayloadAction<string | null>) => {
            state.actions.setDuplicateProperty = false;
            state.propertyDuplicateProperty = action.payload;
        },
        propertySetDuplicatePropertyFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setDuplicateProperty = false;
            if (action.payload) {
                state.error.setDuplicateProperty = action.payload;
            }
        },

        searchingTransaction: (state, action: PayloadAction<string>) => {
            state.searchTransaction = action.payload;
        },

        setPropertyPackagePriceAttempt: (state, _action: SetPropertyPackagePricePayload) => {
            state.actions.setPropertyPackagesPrice = true;
            state.error.setPropertyPackagesPrice = '';
        },
        setPropertyPackagePriceSuccess: (state, action: PayloadAction<string | null>) => {
            state.actions.setPropertyPackagesPrice = false;
            state.error.setPropertyPackagesPrice = '';
            state.setPropertyPackagesPrice = action.payload;
            state.actions.setPropertyPackagesPrice = false;
        },
        setPropertyPackagePriceError: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPropertyPackagesPrice = false;
            if (action.payload) {
                state.error.setPropertyPackagesPrice = action.payload;
            }
        },
        setGoogleDrivePathAttempt: (state, _action: SetGoogleDrivePathPayload) => {
            state.actions.setGoogleDrivePath = true;
            state.error.setGoogleDrivePath = '';
        },
        setGoogleDrivePathSuccess: (state, action: PayloadAction<IProperty | null>) => {
            state.actions.setGoogleDrivePath = false;
            state.error.setGoogleDrivePath = '';

            if (action.payload) {
                state.googleDrivePath = action.payload;
            }
        },
        setGoogleDrivePathError: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setGoogleDrivePath = false;
            if (action.payload) {
                state.error.setGoogleDrivePath = action.payload;
            }
        },
        // ? property group actions
        // ! get property group list
        getPropertyGroupAttempt: (state, _action: GetPropertyGroupPayload) => {
            state.actions.getPropertyGroup = true;
            state.error.getPropertyGroup = '';
        },
        getPropertyGroupSuccess: (state, action: PayloadAction<GetPropertyGroupResponse>) => {
            state.actions.getPropertyGroup = false;

            if (action.payload) {
                state.propertyGroup = action.payload;
            }
        },
        getPropertyGroupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyGroup = false;
            if (action.payload) {
                state.error.getPropertyGroup = action.payload;
            }
        },
        // ! create property group
        createPropertyGroupAttempt: (state, _action: CreatePropertyGroupPayload) => {
            state.actions.createPropertyGroup = true;
            state.error.createPropertyGroup = '';
        },
        createPropertyGroupSuccess: (state, action: PayloadAction<string>) => {
            state.actions.createPropertyGroup = false;
            state.propertyGroupId = action.payload;
        },
        createPropertyGroupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createPropertyGroup = false;
            if (action.payload) {
                state.error.createPropertyGroup = action.payload;
            }
        },
        // ! delete property group
        deletePropertyGroupAttempt: (state, _action: DeletePropertyGroupPayload) => {
            state.actions.deletePropertyGroup = true;
            state.error.deletePropertyGroup = '';
        },
        deletePropertyGroupSuccess: (state) => {
            state.actions.deletePropertyGroup = false;
        },
        deletePropertyGroupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deletePropertyGroup = false;
            if (action.payload) {
                state.error.deletePropertyGroup = action.payload;
            }
        },
        // ! get property group details
        getPropertyGroupDetailsAttempt: (state, _action: GetPropertyGroupDetailsPayload) => {
            state.actions.getPropertyGroupDetails = true;
            state.error.getPropertyGroupDetails = '';
        },
        getPropertyGroupDetailsSuccess: (state, action: PayloadAction<IPropertyGroupDetails>) => {
            state.actions.getPropertyGroupDetails = false;
            if (action.payload) {
                state.propertyGroupDetails = action.payload;
            }
        },
        getPropertyGroupDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyGroupDetails = false;
            if (action.payload) {
                state.error.getPropertyGroupDetails = action.payload;
            }
        },
        // ! edit property group details
        editPropertyGroupDetailsAttempt: (state, _action: EditPropertyGroupDetailsPayload) => {
            state.actions.editPropertyGroupDetails = true;
            state.error.editPropertyGroupDetails = '';
        },
        editPropertyGroupDetailsSuccess: (state) => {
            state.actions.editPropertyGroupDetails = false;
        },
        editPropertyGroupDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editPropertyGroupDetails = false;
            if (action.payload) {
                state.error.editPropertyGroupDetails = action.payload;
            }
        },
        // ! clearing property group details
        clearPropertyGroupDetails: (state) => {
            state.propertyGroupDetails = initialState.propertyGroupDetails;
        },
        // ? changing list view
        setIsPropertyGroupList: (state, action: PayloadAction<boolean>) => {
            state.isPropertyGroupList = action.payload;
        },
        // ! duplicating property group to property list
        duplicatePropertyGroupAttempt: (state, _action: SetDuplicatePropertyGroupActionPayload) => {
            state.actions.setDuplicatePropertyGroup = true;
            state.error.setDuplicatePropertyGroup = '';
        },
        duplicatePropertyGroupSuccess: (state, action: PayloadAction<string | null>) => {
            state.actions.setDuplicatePropertyGroup = false;
        },
        duplicatePropertyGroupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setDuplicatePropertyGroup = false;
            if (action.payload) {
                state.error.setDuplicatePropertyGroup = action.payload;
            }
        },
        // ? opening and closing property group image collapsible
        setPropertyOpenImagesCollapsible: (state, action: PayloadAction<boolean>) => {
            state.propertyImagesCollapsible = action.payload;
        },
        setPropertyImagesSubmitted: (state, action: PayloadAction<boolean>) => {
            state.propertyImagesSubmitted = action.payload;
        },
        // ? checking for location availability
        setShowErrorMessage: (state, action: PayloadAction<boolean>) => {
            state.isMapLocationError = action.payload;
        },
        // ? property actions
        // ! create property
        createPropertyAttempt: (state, _action: CreatePropertyPayload) => {
            state.actions.createProperty = true;
            state.error.createProperty = '';
        },
        createPropertySuccess: (state, action: PayloadAction<string>) => {
            state.actions.createProperty = false;
            state.propertyId = action.payload;
        },
        createPropertyFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createProperty = false;
            if (action.payload) {
                state.error.createProperty = action.payload;
            }
        },
        // ! edit property details
        editPropertyDetailsAttempt: (state, _action: EditPropertyDetailsPayload) => {
            state.actions.editPropertyDetails = true;
            state.error.editPropertyDetails = '';
        },
        editPropertyDetailsSuccess: (state) => {
            state.actions.editPropertyDetails = false;
        },
        editPropertyDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editPropertyDetails = false;
            if (action.payload) {
                state.error.editPropertyDetails = action.payload;
            }
        },
        // ! edit property packages details
        editPropertyPackagesDetailsAttempt: (state, _action: EditPropertyPackagesDetailsPayload) => {
            state.actions.editPropertyPackages = true;
            state.error.editPropertyPackages = '';
        },
        editPropertyPackagesDetailsSuccess: (state, action: PayloadAction<string>) => {
            state.actions.editPropertyPackages = false;
            if (action.payload) {
                state.editPropertyPackages = action.payload;
            }
        },
        editPropertyPackagesDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editPropertyPackages = false;
            if (action.payload) {
                state.error.editPropertyPackages = action.payload;
            }
        },
        getPropertiesReportExportAttempt: (state, _action: GetPropertiesReportExportPayload) => {
            state.actions.getPropertiesReportExport = true;
        },
        getPropertiesReportExportSuccess: (state) => {
            state.actions.getPropertiesReportExport = false;
        },
        getPropertiesReportExportFailure: (state) => {
            state.actions.getPropertiesReportExport = false;
        },
        groupPropertiesWithCountAttempt: (state) => {
            state.actions.groupPropertiesWithCount = true;

            state.error.groupPropertiesWithCount = '';
        },
        groupPropertiesWithCountSuccess: (state, action: PayloadAction<GroupPropertiesWithCountResponse[]>) => {
            state.actions.groupPropertiesWithCount = false;

            if (action.payload) state.groupPropertiesWithCount = action.payload;
        },
        groupPropertiesWithCountFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.groupPropertiesWithCount = false;

            if (action.payload) state.error.groupPropertiesWithCount = action.payload;
        },
        addDesignRenovationAttempt: (state, _action: AddDesignRenovationPayload) => {
            state.actions.addDesignRenovation = true;
            state.error.addDesignRenovation = '';
        },
        addDesignRenovationSuccess: (state) => {
            state.actions.addDesignRenovation = false;
            state.error.addDesignRenovation = '';
        },
        addDesignRenovationFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.addDesignRenovation = false;
            if (action.payload) {
                state.error.addDesignRenovation = action.payload;
            }
        },
        editDesignRenovationAttempt: (state, _action: EditDesignRenovationPayload) => {
            state.actions.editDesignRenovation = true;
            state.error.editDesignRenovation = '';
        },
        editDesignRenovationSuccess: (state) => {
            state.actions.editDesignRenovation = false;
            state.error.editDesignRenovation = '';
        },
        editDesignRenovationFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editDesignRenovation = false;
            if (action.payload) {
                state.error.editDesignRenovation = action.payload;
            }
        },
        deleteDesignRenovationAttempt: (state, _action: DeleteDesignRenovationPayload) => {
            state.actions.deleteDesignRenovation = true;
            state.error.deleteDesignRenovation = '';
        },
        deleteDesignRenovationSuccess: (state) => {
            state.actions.deleteDesignRenovation = false;
            state.error.deleteDesignRenovation = '';
        },
        deleteDesignRenovationFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteDesignRenovation = false;
            if (action.payload) {
                state.error.deleteDesignRenovation = action.payload;
            }
        },
    },
});

export type InitState = typeof initialState;

export default {
    actions: propertySlice.actions,
    reducers: propertySlice.reducer,
};
