import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Actions from 'redux/Actions';

import { Button, Colors } from '@dm/bigfish';

import { IPropertyDesignRenovationDetails } from 'entities/property';

import Modal from 'components/Modal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { PackageCategory } from '@dm/types';
import { toast } from 'react-toastify';
import DesignRenoPackagesInput from './DesignRenoPackagesInput';

interface AddDesignRenovationModalProps {
    id: string;
}

const AddDesignRenovationModal: FC<AddDesignRenovationModalProps> = ({
    id,
}) => {
    const dispatch = useAppDispatch();
    const isAddDesignRenovationModalOpen = useAppSelector((state) => state.property.isAddDesignRenovationModalOpen);
    const propertyDetailsDesignRenovation = useAppSelector((state) => state.property.propertyDetails?.propertyDesignRenoPurchaseHistory);

    const addDesignRenovationAttempt = useAppSelector((state) => state.property.actions.addDesignRenovation);
    const addDesignRenovationError = useAppSelector((state) => state.property.error.addDesignRenovation);

    const editDesignRenovationAttempt = useAppSelector((state) => state.property.actions.editDesignRenovation);
    const editDesignRenovationError = useAppSelector((state) => state.property.error.editDesignRenovation);

    const deleteDesignRenovationAttempt = useAppSelector((state) => state.property.actions.deleteDesignRenovation);
    const deleteDesignRenovationError = useAppSelector((state) => state.property.error.deleteDesignRenovation);

    const [designRenovationDetails, setDesignRenovationDetails] = useState<IPropertyDesignRenovationDetails[]>([]);
    const [isChange, setIsChange] = useState(false);

    useEffect(() => {
        if (!isAddDesignRenovationModalOpen) {
            setDesignRenovationDetails(propertyDetailsDesignRenovation || []);
            setIsChange(false);
        }
    }, [isAddDesignRenovationModalOpen]);

    useEffect(() => {
        if (propertyDetailsDesignRenovation) {
            setDesignRenovationDetails(propertyDetailsDesignRenovation);
        }
    }, [propertyDetailsDesignRenovation]);

    // useEffect(() => {
    //     if (propertyDetailsDesignRenovation && propertyDetailsDesignRenovation.length !== designRenovationDetails.length) {
    //         setIsChange(true);
    //     } else {
    //         setIsChange(false);
    //     }
    // }, [designRenovationDetails]);

    const handleCancelAddDesignRenovation = (index: number) => {
        const filteredData = designRenovationDetails.filter((item, itemIndex) => itemIndex !== index);
        const deletedData = designRenovationDetails.find((item, itemIndex) => itemIndex === index);

        if (deletedData?._id) {
            const packageToDelete = {
                id: deletedData._id,
                packageType: PackageCategory.DesignRenovation,
                propertyId: id,
            };
            dispatch(Actions.deleteDesignRenovationAttempt(packageToDelete));
        } else {
            setDesignRenovationDetails(filteredData);
        }
    };

    const renderModalHeader = () => {
        return (
            'Add Design & Renovation Pakages'
        );
    };

    const renderModalBody = () => {
        if (designRenovationDetails.length > 0) {
            return designRenovationDetails.map((item, index) => {
                return (
                    <DesignRenoPackagesInput
                        key={item._id}
                        designRenovationDetails={item}
                        setDesignRenovationDetails={(designRenoDetails) => setDesignRenovationDetails(prevState => [...prevState.slice(0, index), designRenoDetails, ...prevState.slice(index + 1)])}
                        removeDesignRenovationDetails={() => handleCancelAddDesignRenovation(index)}
                    />
                );
            });
        }
        return (
            <Container>
                <p>There is no active Design & Renovation package yet.</p>
                <Button
                    label='Add new package'
                    onClick={() => setDesignRenovationDetails(prevState => [...prevState, {
                        _id: '',
                        propertyId: id,
                        packageName: '',
                        price: 0,
                        cost: 0,
                        profit: 0,
                        startDate: dayjs().toISOString(),
                    }])}
                />
            </Container>
        );
    };

    const renderModalFooter = () => {
        return (
            <>
                <WarningText>
                    {isChange ? 'You have made some changes! Please click save to update.' : ''}
                </WarningText>

                <div>
                    <Button
                        label='Cancel'
                        style={{
                            marginRight: '10px',
                            backgroundColor: Colors.white,
                            color: Colors.primary,
                            border: `1px solid ${Colors.primary}`,
                        }}
                        onClick={() => dispatch(Actions.setAddDesignRenovationModalOpen(false))}
                    // disabled={editPropertyPackageDetailsAttempt}
                    />
                    <Button
                        label='Add more package'
                        onClick={() => setDesignRenovationDetails(prevState => [...prevState, {
                            _id: '',
                            propertyId: id,
                            packageName: '',
                            price: 0,
                            cost: 0,
                            profit: 0,
                            startDate: dayjs().toISOString(),
                        }])}
                    />
                </div>
            </>
        );
    };

    return (
        <Modal
            show={isAddDesignRenovationModalOpen}
            onClickClose={() => dispatch(Actions.setAddDesignRenovationModalOpen(false))}
            closeCursor
            width='60%'
            height='fit-content'
        >
            <ModalHeader>
                {renderModalHeader()}
            </ModalHeader>

            <ModalBody>
                {renderModalBody()}
            </ModalBody>

            <ModalFooter>
                {designRenovationDetails.length > 0 && renderModalFooter()}
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    margin: 20px 30px;
`;

const ModalBody = styled.div`
    margin: 30px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 30px;
`;

const WarningText = styled.div`
    color: ${Colors.danger};
    font-weight: bold;
    font-style: italic;
    align-items: center;
    display: flex;
`;

const EachPackageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid ${Colors.primaryLight};
    border-radius: 1rem;

    padding: 20px;
`;

const DateTimePicker = styled(ReactDatePicker)`
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0.375rem 0.75rem;
    /* font-size: 1rem; */
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${Colors.black};
    appearance: none;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    &:focus, &:active{
        border-color: rgba(61, 39, 78, 1);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(61, 39, 78, 0.6);
        outline: 0 none;
    }
    &:disabled{
        background-color: ${Colors.grey};
        cursor: not-allowed;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${Colors.primary};
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${Colors.primary};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${Colors.primary};
    }
`;

const InputGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-gap: 20px;

    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: flex-start;
`;

export default AddDesignRenovationModal;
