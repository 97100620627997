import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { EditDesignRenovationPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchEditDesignRenovation(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/editDesignRenovationAttempt', handleEditDesignRenovation, api);
}

function* handleEditDesignRenovation(api: PropertyGateway, data: EditDesignRenovationPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.editDesignRenovationPackages], {
        authToken,
        ...data.payload,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Package updated!');
        yield put(Actions.propertyGetDetailsAttempt({ id: data.payload.propertyId }));
        yield put(Actions.editDesignRenovationSuccess());
        if (!response.data) {
            yield put(Actions.editDesignRenovationFailure(Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.editDesignRenovationFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralMessage.Failed'));
            } else {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            }
        }
    }
}
