import { IAdminDashboard, IDailySettlement, IGetUserInfo } from 'entities/Auth';
import { InitState } from 'redux/slices/auth';

const getStartupAttempting = (state: InitState): boolean => state.actions.startup || false;
const getStartupError = (state: InitState): string => state.error.startup || '';

const getLoginAttempting = (state: InitState): boolean => state.actions.login || false;
const getLoginError = (state: InitState): string => state.error.login || '';

const getAuthToken = (state: InitState): string => state.authToken || '';

const getUserInfo = (state: InitState): IGetUserInfo => state.getUserInfo;
const getAdminDashboard = (state: InitState): IAdminDashboard => state.getAdminDashboard;
const getAdminDashboardAttempt = (state: InitState): boolean => state.actions.getAdminDashboard || false;
const getAdminDashboardFailure = (state: InitState): string => state.error.getAdminDashboard || '';

const getDailySettlement = (state: InitState): IDailySettlement[] => state.dailySettlement;
const getDailySettlementAttempt = (state: InitState): boolean => state.actions.getDailySettlement || false;
const getDailySettlementFailure = (state: InitState): string => state.error.getDailySettlement || '';

const getIsSuperAdmin = (state:InitState) : boolean => state.isSuperAdmin || false;
const getIsApproveSettlementModalOpen = (state:InitState) : boolean => state.isApproveSettlementModalOpen || false;

export default {
    getStartupAttempting,
    getStartupError,

    getLoginAttempting,
    getLoginError,

    getAuthToken,
    getUserInfo,
    getAdminDashboard,
    getAdminDashboardAttempt,
    getAdminDashboardFailure,

    getDailySettlement,
    getDailySettlementAttempt,
    getDailySettlementFailure,

    getIsSuperAdmin,
    getIsApproveSettlementModalOpen,
};
