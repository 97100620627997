import React, { useState } from 'react';
import { Bars, Oval } from 'react-loader-spinner';
import { Button, Colors, Paginator } from '@dm/bigfish';
import Translate from 'i18n-js';
import {
    PMTransactionTypeEnum,
    PropertyManagementTransactionStatus,
    ViewAllPMTransactionResponse,
} from '@dm/types';
import dayjs from 'dayjs';
import NavActions from 'lib/NavActions';
import icons from 'assets/icons';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { UpdateTransactionStatusParams } from 'entities/transactions';
import StyledComponent from '../styles/TransactionsStyle';
import EditTransactionsModal from './EditTransactionsModal';

interface ComponentProps {
    // props from parent
    transactionsData: ViewAllPMTransactionResponse[] | null;
    searchData: string,

    paginationNumber: number;
    paginationData: (string | number)[] | [];
    nextPaginationNumber: () => void;
    prevPaginationNumber: () => void;
    firstPaginationNumber: () => void;
    lastPaginationNumber: () => void;
    onClickPaginationNumber: (pages: number) => void;

    viewFileHandler: (data: ViewAllPMTransactionResponse['fileUrl']) => void;
    uploadFileHandler: (propertyId: string, transactionId: string) => void;
    confirmRefundHandler: (
        propertyId: string,
        transactionId: string,
        data: ViewAllPMTransactionResponse,
    ) => void,

    setTransactionStatusAttempt: (params: UpdateTransactionStatusParams) => void;

    // props from redux
    getPackagesViewAllTransactionsResponseAttempt: boolean;
    getPackagesViewAllTransactionsResponseFailure: string;

    setUpdateTransactionStatusAttempt: boolean;
    setUpdateTransactionStatusFailure: string;

    setEditTransactionModal: (state: boolean) => void;
}

const TransactionListTable = (props: ComponentProps):JSX.Element => {
    const {
        transactionsData,
        searchData,

        paginationNumber,
        paginationData,
        nextPaginationNumber,
        prevPaginationNumber,
        firstPaginationNumber,
        lastPaginationNumber,
        onClickPaginationNumber,

        viewFileHandler,
        uploadFileHandler,
        confirmRefundHandler,

        setTransactionStatusAttempt,

        getPackagesViewAllTransactionsResponseAttempt,
        getPackagesViewAllTransactionsResponseFailure,

        setUpdateTransactionStatusAttempt,
        setUpdateTransactionStatusFailure,

        setEditTransactionModal,
    } = props;

    const {
        StatusContainer,
        PropertyListingTable,
        PropertyListingHeader,
        StyledIconButton,
        Icons,
        PropertyListingRowButton,
        NameContainer,
        PropertyListingsColumnWithImage,
        PropertyListingColumnEmpty,
        LoadingContainer,
        ActionButtonContainer,
    } = StyledComponent;

    const [selectedTransaction, setSelectedTransaction] = useState<ViewAllPMTransactionResponse>();

    const checkPropertyStatus = (type:number) => {
        switch (type) {
            case PropertyManagementTransactionStatus.Accepted:
                return 'Accepted';
            case PropertyManagementTransactionStatus.Pending:
                return 'Pending';
            case PropertyManagementTransactionStatus.Rejected:
                return 'Rejected';
            case PropertyManagementTransactionStatus.Completed:
                return 'Completed';
            case PropertyManagementTransactionStatus.Reverted:
                return 'Reverted';
            default:
                return 'no status';
        }
    };

    const checkPropertyTypes = (type: number) => {
        switch (type) {
            case PMTransactionTypeEnum.topup:
                return 'Topup';
            case PMTransactionTypeEnum.transaction:
                return 'Transaction';
            case PMTransactionTypeEnum.withdrawal:
                return 'Withdrawal';
            case PMTransactionTypeEnum.rental:
                return 'Rental';
            default:
                return 'no status';
        }
    };

    const renderDocumentsActions = (
        propertyId: string,
        transactionId: string,
        data: ViewAllPMTransactionResponse['fileUrl'],
    ) => {
        return (
            <ActionButtonContainer>
                <StyledIconButton
                    disabled={data.length === 0}
                    onClick={() => viewFileHandler(data)}
                    style={{ alignSelf: 'center', display: data.length > 0 ? 'flex' : 'hidden' }}
                >
                    <Icons src={icons.SearchIcon} width='100%' height='20px' />
                </StyledIconButton>
                <StyledIconButton
                    onClick={() => uploadFileHandler(propertyId, transactionId)}
                    style={{ alignSelf: 'center' }}
                >
                    <Icons src={icons.UploadIcon} width='100%' height='20px' color='black' />
                </StyledIconButton>
            </ActionButtonContainer>
        );
    };

    const renderActionButtons = (
        propertyId: string,
        transactionId: string,
        status: number,
        transactionType: number,
        data?: ViewAllPMTransactionResponse,
    ) => {
        if (transactionType === PMTransactionTypeEnum.withdrawal) {
            if (status === PropertyManagementTransactionStatus.Pending) {
                return (
                    <ActionButtonContainer>
                        <StyledIconButton onClick={() => toast.success('Withrawal approved placeholder')}>
                            <Icons src={icons.checkmark} width='30px' height='20px' stroke='green' />
                        </StyledIconButton>
                        <StyledIconButton onClick={() => toast.error('Withdrawal rejected placeholder')}>
                            <Icons src={icons.xmark} width='30px' height='20px' fill='red' />
                        </StyledIconButton>
                    </ActionButtonContainer>
                );
            }
            return null;
        }
        if (transactionType === PMTransactionTypeEnum.rental) {
            if (status === PropertyManagementTransactionStatus.Pending) {
                return (
                    <ActionButtonContainer>
                        <StyledIconButton onClick={() => toast.success('Rental approved placeholder')}>
                            <Icons src={icons.checkmark} width='30px' height='20px' stroke='green' />
                        </StyledIconButton>
                        <StyledIconButton onClick={() => toast.error('Rental rejected placeholder')}>
                            <Icons src={icons.xmark} width='30px' height='20px' fill='red' />
                        </StyledIconButton>
                    </ActionButtonContainer>
                );
            }
            return null;
        }
        if (transactionType === PMTransactionTypeEnum.transaction) {
            if (status === PropertyManagementTransactionStatus.Accepted) {
                const params: UpdateTransactionStatusParams = {
                    propertyId,
                    transactionId,
                    status: PropertyManagementTransactionStatus.Completed,
                    search: searchData || '',
                    transactionType,
                    sort: paginationNumber,
                    index: paginationNumber,
                    currentStatus: status,
                };
                return (
                    <ActionButtonContainer>
                        <Button
                            label='Confirm'
                            onClick={() => setTransactionStatusAttempt(params)}
                        />
                    </ActionButtonContainer>
                );
            }
            if (status === PropertyManagementTransactionStatus.Completed && data) {
                return (
                    <ActionButtonContainer>
                        <StyledIconButton onClick={() => [setEditTransactionModal(true), setSelectedTransaction(data)]}>
                            <Icons src={icons.EditProperty} width='30px' height='20px' />
                        </StyledIconButton>
                        <StyledIconButton onClick={() => confirmRefundHandler(propertyId, transactionId, data)}>
                            <Icons src={icons.revertIcon} width='30px' height='20px' />
                        </StyledIconButton>
                    </ActionButtonContainer>
                );
            }
            return null;
        }
        if (status === PropertyManagementTransactionStatus.Pending) {
            return 'waiting owner approval';
        }
        return null;
    };

    if (getPackagesViewAllTransactionsResponseAttempt) {
        return (
            <LoadingContainer>
                <Bars
                    color={Colors.primary}
                    height={260}
                    width={260}
                />
            </LoadingContainer>
        );
    }

    if (getPackagesViewAllTransactionsResponseFailure) {
        return (
            <PropertyListingColumnEmpty>
                <p>{Translate.t('PropertyReport.PropertyReportError4')}</p>
                <p>{Translate.t('PropertyReport.PropertyReportError5')}</p>
                <p>{Translate.t('PropertyReport.PropertyReportError6')}</p>
            </PropertyListingColumnEmpty>
        );
    }

    if (!transactionsData) {
        return (
            <PropertyListingColumnEmpty>
                <p>{Translate.t('PropertyReport.PropertyReportError1')}</p>
                <p>{Translate.t('PropertyReport.PropertyReportError2')}</p>
                <p>{Translate.t('PropertyReport.PropertyReportError3')}</p>
            </PropertyListingColumnEmpty>
        );
    }

    if (setUpdateTransactionStatusAttempt) {
        return (
            <StatusContainer>
                <Oval
                    height={180}
                    width={180}
                    color={Colors.secondary}
                    secondaryColor={Colors.primary}
                />
            </StatusContainer>

        );
    }

    if (setUpdateTransactionStatusFailure.length) {
        return (
            <div>{Translate.t('PropertyRpTransactions.PropertyRpTransactionFailed')}</div>
        );
    }

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Paginator
                    currentIndex={paginationNumber}
                    data={paginationData}
                    onNext={nextPaginationNumber}
                    onPrev={prevPaginationNumber}
                    onFirst={firstPaginationNumber}
                    onLast={lastPaginationNumber}
                    onClickIndex={onClickPaginationNumber}
                />
            </div>
            <PropertyListingTable>
                <PropertyListingHeader>

                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionpropertyName')}</h4>
                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionpropertyUnitNo')}</h4>
                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionpropertyDescription')}</h4>
                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionpropertyCreatedAt')}</h4>
                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionpropertyUpdatedAt')}</h4>
                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionStatus')}</h4>
                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionTypes')}</h4>
                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionAmount')}</h4>
                    <h4>Documents</h4>
                    <h4>{Translate.t('PropertyRpTransactions.PropertyRpTransactionAction')}</h4>
                </PropertyListingHeader>

                {(transactionsData?.length) && transactionsData.map((property, propertyIndex) => {
                    const {
                        amount,
                        propertyId,
                        propertyName,
                        unitNo,
                        status,
                        transactionType,
                        transactionsId,
                        description,
                        createdAt,
                        updatedAt,
                        fileUrl,
                    } = property;

                    const uniqueKey = propertyIndex + propertyId;

                    return (
                        <PropertyListingRowButton
                            key={uniqueKey}
                        >
                            <PropertyListingsColumnWithImage onClick={() => NavActions.navToPropertyDetails(propertyId)}>
                                <NameContainer>
                                    {propertyName}
                                </NameContainer>
                            </PropertyListingsColumnWithImage>
                            <p>{unitNo ?? 'N/A'}</p>
                            <p>{description}</p>
                            <p>{ createdAt ? dayjs(createdAt).format('YYYY-MM-DD') : null }</p>
                            <p>{ updatedAt ? dayjs(updatedAt).format('YYYY-MM-DD') : null }</p>
                            <p>{checkPropertyStatus(status)}</p>
                            <p>{checkPropertyTypes(transactionType)}</p>
                            {
                                amount > 0 ? (<p style={{ color: Colors.success }}>{`RM ${amount}`}</p>)
                                    : (<p style={{ color: Colors.danger }}>{`RM ${Math.abs(amount)}`}</p>)
                            }
                            {renderDocumentsActions(propertyId, transactionsId, fileUrl)}
                            {renderActionButtons(propertyId, transactionsId, status, transactionType, property)}
                        </PropertyListingRowButton>

                    );
                })}

            </PropertyListingTable>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Paginator
                    currentIndex={paginationNumber}
                    data={paginationData}
                    onNext={nextPaginationNumber}
                    onPrev={prevPaginationNumber}
                    onFirst={firstPaginationNumber}
                    onLast={lastPaginationNumber}
                    onClickIndex={onClickPaginationNumber}
                />
            </div>
            <EditTransactionsModal selectedTransaction={selectedTransaction} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({

    getPackagesViewAllTransactionsResponseAttempt: Selectors.getPackagesViewAllTransactionsResponseAttempt(state),
    getPackagesViewAllTransactionsResponseFailure: Selectors.getPackagesViewAllTransactionsResponseFailure(state),

    setUpdateTransactionStatusAttempt: Selectors.setPackagesPackagesUpdateTransactionStatusAttempt(state),
    setUpdateTransactionStatusFailure: Selectors.setPackagesPackagesUpdateTransactionStatusFailure(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setEditTransactionModal: (state: boolean) => dispatch(Actions.setEditTransactionModal(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionListTable);
