import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { DeleteDesignRenovationPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchDeleteDesignRenovation(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/deleteDesignRenovationAttempt', handleDeleteDesignRenovation, api);
}

function* handleDeleteDesignRenovation(api: PropertyGateway, data: DeleteDesignRenovationPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { propertyId, ...otherParams } = data.payload;

    const response = yield* call([api, api.deleteDesignRenovationPackages], {
        authToken,
        ...otherParams,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Package deleted!');
        yield put(Actions.propertyGetDetailsAttempt({ id: propertyId }));
        yield put(Actions.deleteDesignRenovationSuccess());
        if (!response.data) {
            yield put(Actions.deleteDesignRenovationFailure(Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.deleteDesignRenovationFailure(response.message));

        if (response.message) {
            if (response.message.toLowerCase() === 'try again.') {
                toast.error(Translate.t('GeneralMessage.Failed'));
            } else {
                toast.error(Translate.t('GeneralMessage.TryAgain'));
            }
        }
    }
}
