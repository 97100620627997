import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { Colors, Fonts, PendingCard } from '@dm/bigfish';
import { IAdminDashboard, IDailySettlement } from 'entities/Auth';

import icons from 'assets/icons';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import { DailySettlementStatus, PMTransactionTypeEnum, PaymentStatusEnum, PropertyManagementTransactionStatus, ViewAllPMTransactionResponse } from '@dm/types';
import { IApproveRejectPendingPayment } from 'entities/property';
import media from 'lib/Breakpoints';
import dayjs from 'dayjs';
import ApproveRejectModal from './ApproveRejectModal';
import UploadFileModal from '../../../components/UploadFileModal';
import FileListModal from '../../../components/FileListModal';
import Popover from './Popover';
import ApproveSettlementModal from './ApproveSettlementModal';

interface StatsProps {
    adminDashboard: IAdminDashboard;
    getAdminDashboardAttempt: boolean;
    getAdminDashboardFailure: string;
    getAdminDashboard: () => void;
    authToken: string;
    setPackagesUpdateTransactionStatusAttempt: (
        propertyId: string,
        transactionId: string,
        status: number,
    ) => void;
    setModalOpen: (state: boolean) => void;
    setApproveRejectModalOpen: (state: boolean) => void;
    setApproveRejectParams: (data: IApproveRejectPendingPayment) => void;
    approveRejectLoading: boolean;
    approveRejectError: string;
    setUpdateTransactionStatusAttempt: boolean;
    setUpdateTransactionStatusFailure: string;
    setIsUploadFileModalOpen: (value: boolean) => void;
    setIsFileViewModalOpen: (value: boolean) => void;
    attempLoadingUpload: boolean;
    fileList: ViewAllPMTransactionResponse['fileUrl'];
    setFileList: (value: ViewAllPMTransactionResponse['fileUrl']) => void;
    dailySettlement: IDailySettlement[];
    getDailySettlementAttempt: boolean;
    getDailySettlementFailure: string
    getDailySettlement: (index: number) => void
    setApproveSettlementModal: (state: boolean) => void
}

const Stats = (props: StatsProps): JSX.Element => {
    const {
        adminDashboard,
        getAdminDashboard,
        getAdminDashboardAttempt,
        getAdminDashboardFailure,
        authToken,
        setPackagesUpdateTransactionStatusAttempt,
        setModalOpen,
        setApproveRejectModalOpen,
        setApproveRejectParams,
        approveRejectLoading,
        approveRejectError,
        setUpdateTransactionStatusAttempt,
        setUpdateTransactionStatusFailure,
        setIsUploadFileModalOpen,
        setIsFileViewModalOpen,
        attempLoadingUpload,
        fileList,
        setFileList,
        dailySettlement,
        getDailySettlementAttempt,
        getDailySettlementFailure,
        getDailySettlement,
        setApproveSettlementModal,
    } = props;

    const [selectedPropId, setSelectedPropId] = useState('');
    const [selectedTransId, setSelectedTransId] = useState('');
    const [selectedSettlementId, setSelectedSettlementId] = useState('');
    const [selectedSettlementDate, setSelectedSettlementDate] = useState('');

    useEffect(() => {
        if (authToken !== '') {
            getAdminDashboard();
            getDailySettlement(1);
        }
    }, [setUpdateTransactionStatusAttempt, approveRejectLoading, attempLoadingUpload]);

    const approveRejectClickHandler = (paymentStatus: PaymentStatusEnum, paymentId: string) => {
        setApproveRejectModalOpen(true);

        setApproveRejectParams({
            paymentId,
            status: paymentStatus,
        });
    };

    const uploadFileHandler = (propertyId: string, transactionId: string) => {
        setSelectedPropId(propertyId);
        setSelectedTransId(transactionId);
        setIsUploadFileModalOpen(true);
    };

    const viewFileHandler = (data: ViewAllPMTransactionResponse['fileUrl']) => {
        setFileList(data);
        setIsFileViewModalOpen(true);
    };

    return (
        <StatContainer>
            <PendingCard
                title='TOTAL PAYMENT APPROVALS ON PENDING'
                pendingValue={adminDashboard ? `${adminDashboard.pendingPaymentApproval.total}` : '0'}
                listTitle='Pending Approval'
                onClick={() => null}
                row
                hideViewButton
                loading={getAdminDashboardAttempt || approveRejectLoading}
            >
                {adminDashboard.pendingPaymentApproval.pendingPaymentData.length > 0
                    ? (adminDashboard.pendingPaymentApproval.pendingPaymentData
                        .slice(0, 5)
                        .map((item, index) => {
                            const { owner, receiptCount, amount, propertyName, paymentId } = item;
                            const dataLength = adminDashboard.pendingPaymentApproval.pendingPaymentData.length;
                            return (
                                <>
                                    <TransactionPendingItem key={receiptCount + amount}>
                                        <div>
                                            <p>{owner}</p>
                                            <p style={{
                                                color: `${Colors.grey}`,
                                                fontSize: '14px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                            >
                                                {`${propertyName}`}

                                            </p>
                                        </div>
                                        <div>{`RM ${amount}`}</div>
                                        <span>
                                            <AcceptButton onClick={() => approveRejectClickHandler(PaymentStatusEnum.Approved, paymentId)}>
                                                <ButtonIcon src={icons.checkmark} />
                                            </AcceptButton>
                                            <RejectButton onClick={() => approveRejectClickHandler(PaymentStatusEnum.Rejected, paymentId)}>
                                                <ButtonIcon src={icons.xmark} />
                                            </RejectButton>
                                        </span>
                                    </TransactionPendingItem>
                                    <Divider isLast={index === 4 || index === (dataLength - 1)} />
                                </>
                            );
                        })) : ''}
            </PendingCard>
            <PendingCard
                title='TOTAL TRANSACTION PENDING COMPLETION'
                pendingValue={adminDashboard ? `${adminDashboard.transactionPendingCompletion.total}` : '0'}
                listTitle='Pending Transactions'
                onClick={() => NavActions.navToTransactionsReport()}
                row
                loading={setUpdateTransactionStatusAttempt || attempLoadingUpload || getAdminDashboardAttempt}
            >
                {adminDashboard.transactionPendingCompletion.transactionPendingCompletionData.length > 0
                    ? (adminDashboard.transactionPendingCompletion.transactionPendingCompletionData.slice(0, 4).map((item, index) => {
                        const { amount, description, name, propertyName, propertyId, transactionId, transactionType, fileurl } = item;
                        const dataLength = adminDashboard.transactionPendingCompletion.transactionPendingCompletionData.length;
                        const renderActionButton = () => {
                            if (transactionType === PMTransactionTypeEnum.withdrawal) {
                                // ? placeholder for withrawal
                                return null;
                            } if (transactionType === PMTransactionTypeEnum.rental) {
                                // ? placeholder for rental
                                return (
                                    <span style={{ height: 25 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '10px', alignItems: 'center' }}>
                                            <ImageUploadIcon src={icons.clip} onClick={() => viewFileHandler(fileurl)} />
                                            <p>{fileurl && fileurl.length > 0 ? fileurl.length : 0}</p>
                                        </div>
                                        <AcceptButton onClick={() => setPackagesUpdateTransactionStatusAttempt(propertyId, transactionId, PropertyManagementTransactionStatus.Completed)}>
                                            <ButtonIcon src={icons.checkmark} />
                                        </AcceptButton>
                                        <RejectButton>
                                            <ButtonIcon src={icons.xmark} />
                                        </RejectButton>
                                    </span>
                                );
                            } if (transactionType === PMTransactionTypeEnum.topup) {
                                // ? placeholder for topup
                                return null;
                            }
                            // ? default function for type transaction
                            return (
                                <span style={{ height: 25 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: '10px', alignItems: 'center' }}>
                                        <ImageUploadIcon src={icons.clip} onClick={() => viewFileHandler(fileurl)} />
                                        <p>{fileurl && fileurl.length > 0 ? fileurl.length : 0}</p>
                                    </div>
                                    <AcceptButton onClick={() => setPackagesUpdateTransactionStatusAttempt(propertyId, transactionId, PropertyManagementTransactionStatus.Completed)}>
                                        <ButtonIcon src={icons.checkmark} />
                                    </AcceptButton>
                                    <UploadButton onClick={() => uploadFileHandler(propertyId, transactionId)}>
                                        <ButtonIcon src={icons.UploadIcon} />
                                    </UploadButton>
                                </span>
                            );
                        };
                        return (
                            <>
                                <TransactionPendingItem key={propertyName + amount}>
                                    <div>
                                        <p>{name}</p>
                                        <p>{propertyName}</p>
                                        <p>{description}</p>
                                    </div>
                                    <div>
                                        <p>
                                            {`RM ${amount}`}
                                        </p>
                                    </div>
                                    {renderActionButton()}
                                </TransactionPendingItem>
                                <Divider isLast={index === 3 || index === (dataLength - 1)} />
                            </>
                        );
                    })) : ''}
            </PendingCard>
            <PendingCard
                title='TOTAL SETTLEMENT APPROVALS'
                pendingValue={dailySettlement ? `${dailySettlement.filter((item) => item.status === DailySettlementStatus.pending).length}` : '0'}
                listTitle='Pending Daily Settlements'
                loading={getAdminDashboardAttempt || getDailySettlementAttempt}
                onClick={() => null}
                row
                hideViewButton
            >
                <div style={{ height: '300px' }}>
                    {dailySettlement.length > 0
                        ? (dailySettlement.map((item) => {
                            const { _id, settlementDate, totalTransactionAmount, status, transactions } = item;
                            if (status === DailySettlementStatus.approved) return null;
                            return (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Popover
                                        date={settlementDate}
                                        amount={totalTransactionAmount}
                                        transactionsDetails={transactions}
                                    />
                                    <AcceptButton onClick={() => [
                                        setSelectedSettlementId(_id),
                                        setSelectedSettlementDate(settlementDate),
                                        setApproveSettlementModal(true),
                                    ]}
                                    >
                                        <ButtonIcon src={icons.checkmark} />
                                    </AcceptButton>
                                </div>
                            );
                        })) : ''}
                </div>
            </PendingCard>
            <ApproveRejectModal />
            <ApproveSettlementModal
                settlementId={selectedSettlementId}
                settlementDate={selectedSettlementDate}
            />
            <UploadFileModal
                propertyId={selectedPropId}
                transactionId={selectedTransId}
            />
            <FileListModal />
        </StatContainer>
    );
};

const StatContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    ${media.lg`
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
    /* padding-right: 40px; */
    `}

`;

const ViewButton = styled.button`
    font-weight: 600;
    font-family: ${Fonts.primary};
    color: ${Colors.white};
    background-color: ${Colors.primary};
    border-radius: 20px;
    border: none;
    width: 80px;
    height: 35px;
    transition: scale(1) 0.3s ease;

    &:hover {
        background-color: ${Colors.secondaryLight};
    }
    &:active {
        transform: scale(0.95);
    }
`;

const AcceptButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.white};
    background-color: ${Colors.success};
    border-radius: 50%;
    border: none;
    padding: 4px;
    margin-right: 3px;
    transition: scale(1) 0.3s ease;
    height: 25px;

    &:hover {
        background-color: #1f9775;
    }
    &:active {
        transform: scale(0.95);
    }
`;

const RejectButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.white};
    background-color: ${Colors.danger};
    border-radius: 50%;
    border: none;
    padding: 4px;
    transition: scale(1) 0.3s ease;
    height: 25px;

    &:hover {
        background-color: #a33333;
    }
    &:active {
        transform: scale(0.95);
    }
`;

const UploadButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.black};
    background-color: ${Colors.primary};
    border-radius: 50%;
    border: none;
    padding: 4px;
    transition: scale(1) 0.3s ease;
    height: 25px;

    &:hover {
        background-color: ${Colors.secondaryLight};
    }
    &:active {
        transform: scale(0.95);
    }
`;

const ButtonIcon = styled(SVG)`
    width: 16px;
    height: 16px;
    color: ${Colors.white};
`;

const ImageUploadIcon = styled(SVG)`
    width: 20px;
    height: fit-content;
    color: ${Colors.black};

    &:hover {
        color: ${Colors.secondaryLight};
        cursor: pointer;
    }
    &:active {
        transform: scale(0.95);
    }
`;

const TransactionPendingItem = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    align-items: center;

    div:nth-child(1){
        grid-area: 1 / 1 / 2 / 2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            ${media.xl`
                font-size: 14px;
            `}
        }

        p:not(:first-child){
            color: ${Colors.grey};
            font-size: 14px;
            ${media.xl`
                font-size: 10px;
            `}
        }
    }

    div:nth-child(2){
        grid-area: 1 / 2 / 2 / 3;

        p{
            text-align: center;
        }
    }

    span{
        grid-area: 1 / 3 / 2 / 4;
        display: flex;
        justify-content: right;

        ${media.xl`
            justify-content: space-between;
        `}
    }
`;

const Divider = styled.hr<{ isLast: boolean }>`
    display: ${props => (props.isLast ? 'none' : 'block')};
    border-top: 1px solid ${Colors.grey};
`;

const mapStateToProps = (state: RootState) => ({
    adminDashboard: Selectors.getAuthGetAdminDashboard(state),
    getAdminDashboardAttempt: Selectors.getAuthGetAdminDashboardAttempt(state),
    getAdminDashboardFailure: Selectors.getAuthGetAdminDashboardFailure(state),
    authToken: Selectors.getAuthAuthToken(state),
    setUpdateTransactionStatusAttempt: Selectors.setPackagesPackagesUpdateTransactionStatusAttempt(state),
    setUpdateTransactionStatusFailure: Selectors.setPackagesPackagesUpdateTransactionStatusFailure(state),
    attempLoadingUpload: Selectors.packagesgetPackagesUploadUrlAttempting(state),
    fileList: Selectors.getTransactionGetFileList(state),
    approveRejectLoading: Selectors.getPaymentApproveRejectPaymentAttempting(state),
    approveRejectError: Selectors.getPaymentApproveRejectPaymentError(state),
    dailySettlement: Selectors.getAuthGetDailySettlement(state),
    getDailySettlementAttempt: Selectors.getAuthGetDailySettlementAttempt(state),
    getDailySettlementFailure: Selectors.getAuthGetDailySettlementFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getAdminDashboard: () => dispatch(Actions.getAdminDashboardAttempt()),
    getDailySettlement: (index: number) => dispatch(Actions.getDailySettlementAttempt({ index })),
    setPackagesUpdateTransactionStatusAttempt: (
        propertyId: string,
        transactionId: string,
        status: number,
    ) => dispatch(Actions.packagesUpdateTransactionStatusAttempt({ propertyId, transactionId, status })),
    setModalOpen: (state: boolean) => dispatch(Actions.setReceiptImageModalOpen(state)),
    setApproveRejectModalOpen: (state: boolean) => dispatch(Actions.setApproveRejectModalOpen(state)),
    setApproveRejectParams: (data: IApproveRejectPendingPayment) => dispatch(Actions.setApproveRejectParams(data)),
    setIsUploadFileModalOpen: (value: boolean) => dispatch(Actions.setUploadFileModalOpen(value)),
    setIsFileViewModalOpen: (state: boolean) => dispatch(Actions.setFileViewModalOpen(state)),
    setFileList: (data: ViewAllPMTransactionResponse['fileUrl']) => dispatch(Actions.setFileList(data)),
    setApproveSettlementModal: (state: boolean) => dispatch(Actions.setIsApproveSettlementModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
