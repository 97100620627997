import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import Translate from 'lib/translate';

import { SagaWatcherReturnType } from 'sagas/types';

import PropertyGateway from 'api/Property';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import { AddDesignRenovationPayload } from 'redux/slices/property/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchAddDesignRenovation(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/addDesignRenovationAttempt', handleAddDesignRenovation, api);
}

function* handleAddDesignRenovation(api: PropertyGateway, data: AddDesignRenovationPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { endDate, ...params } = data.payload;
    const response = yield* call([api, api.addDesignRenovationPackages], {
        authToken,
        endDate: endDate || undefined,
        ...params,
    });

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Package added!');
        yield put(Actions.propertyGetDetailsAttempt({ id: data.payload.propertyId }));
        yield put(Actions.addDesignRenovationSuccess());
        if (!response.data) {
            yield put(Actions.addDesignRenovationFailure(Translate.t('GeneralMessage.SomethingWrong')));
        }
    } else {
        yield put(Actions.addDesignRenovationFailure(response.message));
        toast.error(response.message);
    }
}
