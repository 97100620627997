import { DailySettlementStatus, IDailyPropertyManagementTransactionSettlement } from '@dm/types';
import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IAdminDashboard, IDailySettlement, IDailySettlementList, IGetUserInfo } from 'entities/Auth';

export interface LoginParams {
    email: string;
    password: string;
    rememberMe?: boolean;
}

export interface LoginResponse {
    token: string;
}

export interface GetUserInfoParams {
    data: IGetUserInfo;
    authToken: string;
}

export interface GetAdminDashboardParams{
    authToken: string;
}
export interface GetDailySettlementParams{
    authToken: string;
    index: number;
}

export interface ApproveDailySettlementParams {
    authToken: string;
    id: string;
    status: DailySettlementStatus;
}

export abstract class IAuthGateway extends Gateway {
    abstract login(params: LoginParams): GatewayResponse<LoginResponse | null>;

    abstract getUserInfo(params: GetUserInfoParams): GatewayResponse<IGetUserInfo>;

    abstract getAdminDashboard(params: GetAdminDashboardParams): GatewayResponse<IAdminDashboard>;

    abstract getDailySettlement(params: GetDailySettlementParams): GatewayResponse<IDailySettlementList>;

    abstract approveDailySettlement(params: ApproveDailySettlementParams): GatewayResponse<string | null>;
}
