import auth from 'redux/slices/auth/Selectors';
import ui from 'redux/slices/ui/Selectors';
import owners from 'redux/slices/owners/Selectors';
import property from 'redux/slices/property/Selectors';
import payment from 'redux/slices/payment/Selectors';
import packages from 'redux/slices/packages/Selectors';
import rea from 'redux/slices/rea/Selectors';
import subadmins from 'redux/slices/subadmins/Selectors';

import { IServerOwnerReport } from 'entities/owners';
import { ILeaseSale, IPackageTypeEnum } from 'entities/packages';

import { IApproveRejectPendingPayment, IPropertyGroupDetails, IPropertyOwners, IServerPropertyReport } from 'entities/property';

import {
    GetOwnerDetailsResponse,
    GetPendingPaymentResponse,
    IProperty,
    IGetEventTemplate,
    AdminGetPropertiesResponse,
    AdminViewAllCoBrokeResponse,
    GetREADetailsResponse,
    READetailsWithPropertyInfo,
    IVacantPossession,
    IVacantManagement,
    IPropertyManagement,
    PropertyManagementTransactionResponse,
    PaginatedViewAllPMTransactionsResponse,
    IDefectInspection,
    ViewAllPMTransactionResponse,
    GetPropertyGroupResponse,
    IPaginatedViewAllRecurringPMTransactionResponse,
    IPaginatedAdminPropertyManagementWithdrawalResponse,
    GroupPropertiesWithCountResponse,
} from '@dm/types';

import { IAdminDashboard, IDailySettlement, IGetUserInfo } from 'entities/Auth';
import { ISubAdminPropertyWithId, ISubAdminsReport } from 'entities/subadmins';
import { ViewTransactionsParams } from 'entities/transactions';
import { RootState } from './store';
import { Coordinates, CoordinatesAddress1, CoordinatesAddress2, CoordinatesCity, CoordinatesCountry, CoordinatesPoscode, CoordinatesState } from './slices/property/types';
import { SetCreatePMTransaction } from './slices/packages/types';

// Auth
const getAuthStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);
const getAuthStartupError = (state: RootState): string => auth.getStartupError(state.auth);

const getAuthLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const getAuthLoginError = (state: RootState): string => auth.getLoginError(state.auth);
const getAuthAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);

const getAuthGetUserInfo = (state: RootState): IGetUserInfo => auth.getUserInfo(state.auth);
const getAuthGetAdminDashboard = (state: RootState): IAdminDashboard => auth.getAdminDashboard(state.auth);
const getAuthGetAdminDashboardAttempt = (state: RootState): boolean => auth.getAdminDashboardAttempt(state.auth);
const getAuthGetAdminDashboardFailure = (state: RootState): string => auth.getAdminDashboardFailure(state.auth);

const getAuthGetDailySettlement = (state: RootState): IDailySettlement[] => auth.getDailySettlement(state.auth);
const getAuthGetDailySettlementAttempt = (state: RootState): boolean => auth.getDailySettlementAttempt(state.auth);
const getAuthGetDailySettlementFailure = (state: RootState): string => auth.getDailySettlementFailure(state.auth);

const getAuthGetIsSuperAdmin = (state: RootState): boolean => auth.getIsSuperAdmin(state.auth);
const getAuthGetIsApproveSettlementModalOpen = (state: RootState): boolean => auth.getIsApproveSettlementModalOpen(state.auth);

// UI
const getUiSelectedLanguage = (state: RootState): string => ui.getSelectedLanguage(state.ui);
const getUiSelectedTabView = (state: RootState): string => ui.getSelectedTabView(state.ui);
const getUiTransactionsFilter = (state: RootState): ViewTransactionsParams => ui.getTransactionsFilter(state.ui);
const getUiModulesCreateModal = (state: RootState): boolean => ui.getModulesCreateModal(state.ui);
const getUiEditTransactionModal = (state: RootState): boolean => ui.getEditTransactionModal(state.ui);
const getUiEditRecurringTransactionModal = (state: RootState): boolean => ui.getEditRecurringTransactionModal(state.ui);
const getUiCreateOwnerModal = (state: RootState): boolean => ui.getCreateOwnerModal(state.ui);

// Owners
const getOwnersCreateNewAttempt = (state: RootState): boolean => owners.getCreateNewAttempt(state.owners);
const getOwnersCreateNewError = (state: RootState): string => owners.getCreateNewError(state.owners);

const getOwnersGetAllAttempt = (state: RootState): boolean => owners.ownersGetAllAttempt(state.owners);
const getOwnersGetAllError = (state: RootState): string => owners.ownersGetAllError(state.owners);

const getOwnersOwnersReport = (state: RootState): IServerOwnerReport => owners.ownersReport(state.owners);

const getOwnersGetOwnerDetailsAttempting = (state: RootState): boolean => owners.getOwnerDetailsAttempting(state.owners);
const getOwnersGetOwnerDetailsError = (state: RootState): string => owners.getOwnerDetailsError(state.owners);

const getOwnersGetOwnerDetails = (state: RootState): GetOwnerDetailsResponse | null => owners.getOwnerDetails(state.owners);

const getOwnersEditOwnerDetailsAttempt = (state: RootState): boolean => owners.editOwnerDetailsAttempt(state.owners);
const getOwnersEditOwnerDetailsError = (state: RootState): string => owners.editOwnerDetailsError(state.owners);

// Property
const getPropertyGetAllAttempt = (state: RootState): boolean => property.propertyGetAllAttempt(state.property);
const getPropertyGetAllError = (state: RootState): string => property.propertyGetAllError(state.property);

const getPropertyPropertyReport = (state: RootState): IServerPropertyReport => property.propertyReport(state.property);

const getPropertyGetDetailsAttempt = (state: RootState): boolean => property.propertyGetDetailsAttempt(state.property);
const getPropertyGetDetailsError = (state: RootState): string => property.propertyGetDetailsError(state.property);

const getPropertyPropertyDetails = (state: RootState): IProperty | null => property.propertyDetails(state.property);

const getPropertySetServicePackageAttempting = (state: RootState): boolean => property.setServicePackageAttempting(state.property);
const getPropertySetServicePackageError = (state: RootState): string => property.setServicePackageError(state.property);

const getPropertySetPropertyPackagesPriceAttempting = (state: RootState): boolean => property.setPropertyPackagesPriceAttempting(state.property);
const getPropertySetPropertyPackagesPriceError = (state: RootState): string => property.setPropertyPackagesPriceError(state.property);

const getPropertySetGoogleDrivePathAttempting = (state: RootState): boolean => property.setGoogleDrivePathAttempting(state.property);
const getPropertySetGoogleDrivePathError = (state: RootState): string => property.setGoogleDrivePathError(state.property);
const getPropertyGoogleDrivePath = (state: RootState): IProperty | null => property.googleDrivePathData(state.property);

const getPropertyGetPropertyGroupAttempting = (state: RootState): boolean => property.getPropertyGroupAttempt(state.property);
const getPropertyGetPropertyGroupError = (state: RootState): string => property.getPropertyGroupError(state.property);

const getPropertyPropertyGroup = (state: RootState): GetPropertyGroupResponse => property.propertyGroup(state.property);

const getPropertyCreatePropertyGroupAttempting = (state: RootState): boolean => property.createPropertyGroupAttempting(state.property);
const getPropertyCreatePropertyGroupError = (state: RootState): string => property.createPropertyGroupError(state.property);
const getPropertyPropertyGroupId = (state: RootState): string => property.propertyGroupId(state.property);

const getPropertyDeletePropertyGroupAttempting = (state: RootState): boolean => property.deletePropertyGroupAttempting(state.property);
const getPropertyDeletePropertyGroupError = (state: RootState): string => property.deletePropertyGroupError(state.property);

const getPropertyGetPropertyGroupDetailsAttempting = (state: RootState): boolean => property.getPropertyGroupDetailsAttempt(state.property);
const getPropertyGetPropertyGroupDetailsError = (state: RootState): string => property.getPropertyGroupDetailsError(state.property);

const getPropertyPropertyGroupDetails = (state: RootState): IPropertyGroupDetails => property.propertyGroupDetails(state.property);

const getPropertyEditPropertyGroupDetailsAttempting = (state: RootState): boolean => property.editPropertyGroupDetailsAttempt(state.property);
const getPropertyEditPropertyGroupDetailsError = (state: RootState): string => property.editPropertyGroupDetailsError(state.property);

const getPropertyIsPropertyGroupList = (state: RootState): boolean => property.isPropertyGroupList(state.property);

const getPropertyDuplicatePropertyGroupAttempt = (state: RootState): boolean => property.duplicatePropertyGroupAttempt(state.property);
const getPropertyDuplicatePropertyGroupError = (state: RootState): string => property.duplicatePropertyGroupError(state.property);

const setIsPropertyOpenImagesCollapsible = (state: RootState): boolean => property.setIsPropertyOpenImagesCollapsible(state.property);
const setPropertySetPropertyImagesSubmitted = (state: RootState): boolean => property.setPropertyImagesSubmitted(state.property);

const setPropertySetShowErrorMessage = (state: RootState): boolean => property.setShowErrorMessage(state.property);

const getPropertyCreatePropertyAttempting = (state: RootState): boolean => property.createPropertyAttempting(state.property);
const getPropertyCreatePropertyError = (state: RootState): string => property.createPropertyError(state.property);
const getPropertyPropertyId = (state: RootState): string => property.propertyId(state.property);

const getPropertyEditPropertyDetailsAttempting = (state: RootState): boolean => property.editPropertyDetailsAttempt(state.property);
const getPropertyEditPropertyDetailsError = (state: RootState): string => property.editPropertyDetailsError(state.property);

const getPropertyEditPropertyPackagesDetailsAttempting = (state: RootState): boolean => property.editPropertyPackagesDetailsAttempt(state.property);
const getPropertyEditPropertyPackagesDetailsError = (state: RootState): string => property.editPropertyPackagesDetailsError(state.property);

const getPropertyPropertyImagesUploadedFile = (state: RootState): File[] => property.propertyImagesUploadedFile(state.property);
const getPropertyPropertyImagesDeletedString = (state: RootState): string[] => property.propertyImagesDeletedString(state.property);

const getPropertyGetServiceModalOpen = (state: RootState): boolean => property.isServicePackageModalOpen(state.property);
const getPropertyGetPackagePriceModalOpen = (state: RootState): boolean => property.isUpdatePackagePriceModalOpen(state.property);
const getPropertyGetGoogleDrivePathModalOpen = (state: RootState): boolean => property.isSetGoogleDrivePathModalOpen(state.property);
const getPropertyGetMilestoneModalOpen = (state: RootState): boolean => property.isSetMilestonesModalOpen(state.property);
const getPropertyGetRejectProperyModalOpen = (state: RootState): boolean => property.isRejectPropertyModalOpen(state.property);

const getPropertyGetSearchedPropertyAttempting = (state: RootState): boolean => property.getSearchedPropertyAttempt(state.property);
const getPropertyGetSearchedPropertyError = (state: RootState): string => property.getSearchedPropertyError(state.property);

const getPropertySearchProperty = (state: RootState): AdminGetPropertiesResponse[] | [] => property.searchedProperty(state.property);
const packagesSetVacantPossesionModal = (state: RootState): boolean => packages.setVacantPossesionModal(state.packages);

const setPropertySetPropertySetDescriptionAttempting = (state: RootState): boolean => property.setPropertySetDescriptionAttempting(state.property);
const setPropertySetPropertySetDescriptionError = (state: RootState): string => property.setPropertySetDescriptionError(state.property);
const setPropertySetPropertySetDescription = (state: RootState): string => property.setPropertySetDescription(state.property);

const setPropertySetPropertySetPropertyAmenitiesAttempting = (state: RootState): boolean => property.setPropertySetPropertyAmenitiesAttempting(state.property);
const setPropertySetPropertySetPropertyAmenitiesError = (state: RootState): string => property.setPropertySetPropertyAmenitiesError(state.property);
const setPropertySetPropertySetPropertyAmenities = (state: RootState): string => property.setPropertySetPropertyAmenities(state.property);

const setPropertySetPropertySetPropertyDetailsAttempting = (state: RootState): boolean => property.setPropertySetPropertyDetailsAttempting(state.property);
const setPropertySetPropertySetPropertyDetailsError = (state: RootState): string => property.setPropertySetPropertyDetailsError(state.property);
const setPropertySetPropertySetPropertyDetails = (state: RootState): string => property.setPropertySetPropertyDetails(state.property);

const setPropertySetPropertySetPropertyUserAttempting = (state: RootState): boolean => property.setPropertySetPropertyUserAttempting(state.property);
const setPropertySetPropertySetPropertyUserError = (state: RootState): string => property.setPropertySetPropertyUserError(state.property);
const setPropertySetPropertySetPropertyUser = (state: RootState): string => property.setPropertySetPropertyUser(state.property);

const setPropertySetPropertySetPropertyLocationAttempting = (state: RootState): boolean => property.setPropertySetPropertyLocationAttempting(state.property);
const setPropertySetPropertySetPropertyLocationError = (state: RootState): string => property.setPropertySetPropertyLocationError(state.property);
const setPropertySetPropertySetPropertyLocation = (state: RootState): string => property.setPropertySetPropertyLocation(state.property);

const setPropertyGetPropertySetCreatePropertAttempting = (state: RootState): boolean => property.setPropertySetCreatePropertAttempting(state.property);
const setPropertyGetPropertySetCreatePropertError = (state: RootState): string => property.setPropertySetCreatePropertError(state.property);
const setPropertyGetPropertySetCreatePropert = (state: RootState): string => property.setPropertySetCreatePropert(state.property);

const setPropertyCreatePropertyModal = (state: RootState): boolean => property.setPropertyCreatePropertyModal(state.property);

const getPropertyGetSearchedOwnerAttempting = (state: RootState): boolean => property.getSearchedOwnerAttempt(state.property);
const getPropertyGetSearchedOnerError = (state: RootState): string => property.getSearchedOwnerError(state.property);

const getPropertySearchedOwner = (state: RootState): IPropertyOwners | null => property.searchedOwner(state.property);

const getPropertyGetCoordinates = (state: RootState): Coordinates => property.getPropertyGetCoordinates(state.property);

const getPropertyGetAddress1 = (state: RootState): CoordinatesAddress1 => property.getPropertyGetAddress1(state.property);

const getPropertyGetAddress2 = (state: RootState): CoordinatesAddress2 => property.getPropertyGetAddress2(state.property);

const getPropertyGetPoscode = (state: RootState): CoordinatesPoscode => property.getPropertyGetPoscode(state.property);

const getPropertyGetState = (state: RootState): CoordinatesState => property.getPropertyGetState(state.property);

const getPropertyGetCountry = (state: RootState): CoordinatesCountry => property.getPropertyGetCountry(state.property);

const getPropertyGetCity = (state: RootState): CoordinatesCity => property.getPropertyGetCity(state.property);

const userGetPropertyUploadUrlAttempting = (state: RootState): boolean => property.getPropertyUploadUrlAttempting(state.property);
const userGetPropertyUploadUrlError = (state: RootState): string => property.getPropertyUploadUrlError(state.property);

const userGetPropertyUploadUrl = (state: RootState): string => property.getPropertyUploadUrl(state.property);

const userUploadPropertyImageAttempting = (state: RootState): boolean => property.uploadPropertyImageAttempting(state.property);
const userUploadPropertyImageError = (state: RootState): string => property.uploadPropertyImageError(state.property);

const setPropertysetIsImageUploadComplete = (state: RootState): boolean => property.setIsPropertyPropertyImageSuccess(state.property);

const setPropertySetDeleteImageAttempting = (state: RootState): boolean => property.setPropertySetDeleteImageAttempting(state.property);
const setPropertySetDeleteImageError = (state: RootState): string => property.setPropertySetDeleteImageError(state.property);
const setPropertySetDeleteImage = (state: RootState): string => property.setPropertySetDeleteImage(state.property);

const getPropertyGetPropertyDetailsAttempting = (state: RootState): boolean => property.getPropertyGetPropertyDetailsAttempting(state.property);
const getPropertyGetPropertyDetailsError = (state: RootState): string => property.getPropertyGetPropertyDetailsError(state.property);
const getPropertyGetPropertyDetails = (state: RootState): IProperty | null => property.getPropertyDetails(state.property);

const setPropertysIsPropertyDataLoaded = (state: RootState): boolean => property.setPropertysIsPropertyDataLoaded(state.property);

const setPropertysLocationLatitudeFirstLoad = (state: RootState): number => property.setPropertysLocationLatitudeFirstLoad(state.property);
const setPropertysLocationLongitudeFirstLoad = (state: RootState): number => property.setPropertysLocationLongitudeFirstLoad(state.property);

const propertySetPropertyLocationSubmitted = (state: RootState): boolean => property.setPropertyLocationSubmitted(state.property);

const setPropertySetPropertyTenancyStatusAttempting = (state: RootState): boolean => property.setPropertyTenancyStatusAttempting(state.property);
const setPropertySetPropertyTenancyStatusError = (state: RootState): string => property.setPropertyTenancyStatusError(state.property);
const setPropertySetPropertyTenancyStatusSuccess = (state: RootState): string | null => property.setPropertyTenancyStatusSuccess(state.property);

const getPropertyGetVacancyStatusModalOpen = (state: RootState): boolean => property.isSVacancyStatusPackageModalOpen(state.property);

const setPropertySetDuplicatePropertyAttempting = (state: RootState): boolean => property.setPropertysetDuplicatePropertyAttempting(state.property);
const setPropertySetDuplicatePropertyError = (state: RootState): string => property.setPropertsetDuplicatePropertyyError(state.property);
const setPropertySetDuplicatePropertySuccess = (state: RootState): string | null => property.setPropertyDuplicateSuccess(state.property);

const setPropertySearchTransaction = (state: RootState): string => property.propertySearchTransaction(state.property);

const getPropertiesReportExportAttempt = (state: RootState): boolean => property.getPropertiesReportExportAttempt(state.property);

const groupPropertiesWithCountAttempt = (state: RootState): boolean => property.groupPropertiesWithCountAttempt(state.property);
const groupPropertiesWithCount = (state: RootState): GroupPropertiesWithCountResponse[] => property.groupPropertisWithCount(state.property);

// Payments
const getPaymentGetAllPaymentsAttempting = (state: RootState): boolean => payment.getAllPaymentAttempting(state.payment);
const getPaymentGetAllPaymentsError = (state: RootState): string => payment.getAllPaymentError(state.payment);

const getPaymentGetAllPayments = (state: RootState): GetPendingPaymentResponse => payment.getAllPaymentData(state.payment);

const getPaymentGetIsReceiptImagesModalOpen = (state: RootState): boolean => payment.getIsReceiptImagesModalOpen(state.payment);

const getPaymentGetIsApproveRejectModalOpen = (state: RootState): boolean => payment.getIsApproveRejectModalOpen(state.payment);
const getPaymentGetApproveRejectParams = (state: RootState): IApproveRejectPendingPayment => payment.getApproveRejectParams(state.payment);

const getPaymentApproveRejectPaymentAttempting = (state: RootState): boolean => payment.getApproveRejectPaymentAttempting(state.payment);
const getPaymentApproveRejectPaymentError = (state: RootState): string => payment.getApproveRejectPaymentError(state.payment);

const getTransactionGetTransactionModalOpen = (state: RootState): boolean => payment.getTransactionModalOpen(state.payment);
const getTransactionGetUploadFileModalOpen = (state: RootState): boolean => payment.getIsUploadFileModalOpen(state.payment);
const getTransactionGetFileViewModalOpen = (state: RootState): boolean => payment.getIsFileViewModalOpen(state.payment);
const getTransactionGetExportModalOpen = (state: RootState): boolean => payment.getIsExportModalOpen(state.payment);
const getTransactionGetConfirmRefundModalOpen = (state: RootState): boolean => payment.getIsConfirmRefundModalOpen(state.payment);

const getTransactionGetFileList = (state: RootState): ViewAllPMTransactionResponse['fileUrl'] => payment.getFileList(state.payment);

const getTransactionGetTransactionReportExportAttempt = (state: RootState): boolean => payment.getTransactionReportExportAttempt(state.payment);
// Recent Event
const getPackagesCreateEventAttempting = (state: RootState): boolean => packages.getCreateEventAttempting(state.packages);
const getPackagesCreateEventError = (state: RootState): string => packages.getCreateEventError(state.packages);
const getPackagesSelectedRecentEvent = (state: RootState): IPackageTypeEnum => packages.selectedRecentEvent(state.packages);
const getPackagesGetEventTemplateAttempting = (state: RootState): boolean => packages.getCreateEventAttempting(state.packages);
const getPackagesGetEventTemplateError = (state: RootState): string => packages.getEventTemplateError(state.packages);
const getPackagesEventTemplates = (state: RootState): IGetEventTemplate[] => packages.eventTemplates(state.packages);

const getPackagesDataVacantPossesionDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesDataVacantPossesionDetailsSortAttempt(state.packages);
const getPackagesDataVacantPossesionDetailsSortFailure = (state: RootState): string => packages.getPackagesDataVacantPossesionDetailsSortFailure(state.packages);
const getPackagesDataVacantPossesionDetailsSort = (state: RootState): IVacantPossession | null => packages.getPackagesDataVacantPossesionDetailsSort(state.packages);

const getPackagesDataDefectInspectionDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesDataDefectInspectionDetailsSortAttempt(state.packages);
const getPackagesDataDefectInspectionDetailsSortFailure = (state: RootState): string => packages.getPackagesDataDefectInspectionDetailsSortFailure(state.packages);
const getPackagesDataDefectInspectionDetailsSort = (state: RootState): IDefectInspection | null => packages.getPackagesDataDefectInspectionDetailsSort(state.packages);

const getPackagesDataVacantManagementDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesDataVacantManagementDetailsSortAttempt(state.packages);
const getPackagesDataVacantManagementDetailsSortFailure = (state: RootState): string => packages.getPackagesDataVacantManagementDetailsSortFailure(state.packages);
const getPackagesDataVacantManagementDetailsSort = (state: RootState): IVacantManagement | null => packages.getPackagesDataVacantManagementDetailsSort(state.packages);

const getPackagesDataPropertyManagementDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesDataPropertyManagementDetailsSortAttempt(state.packages);
const getPackagesDataPropertyManagementDetailsSortFailure = (state: RootState): string => packages.getPackagesDataPropertyManagementDetailsSortFailure(state.packages);
const getPackagesDataPropertyManagementDetailsSort = (state: RootState): IPropertyManagement | null => packages.getPackagesDataPropertyManagementDetailsSort(state.packages);

const getPackagesIPropertyManagementDataDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesPropertyManagementDetailsSortAttempt(state.packages);
const getPackagesIPropertyManagementDataDetailsSortFailure = (state: RootState): string => packages.getPackagesPropertyManagementDetailsSortFailure(state.packages);
const getIPropertyManagementDataDetailsSort = (state: RootState): PropertyManagementTransactionResponse[] | null => packages.getPackagesPropertyManagementDetailsSort(state.packages);

const getPackagesViewAllTransactionsResponseAttempt = (state: RootState): boolean => packages.getPaginatedViewAllTransactionsResponseAttempt(state.packages);
const getPackagesViewAllTransactionsResponseFailure = (state: RootState): string => packages.getPaginatedViewAllTransactionsResponseFailure(state.packages);
const getViewAllTransactionsResponse = (state: RootState): PaginatedViewAllPMTransactionsResponse | null => packages.getPaginatedViewAllTransactionsResponse(state.packages);

const getPackagesRecurringTransactionsResponseAttempt = (state: RootState): boolean => packages.getRecurringTransactionsResponseAttempt(state.packages);
const getPackagesRecurringTransactionsResponseFailure = (state: RootState): string => packages.getRecurringTransactionsResponseFailure(state.packages);
const getPackagesRecurringTransactionsResponse = (state: RootState): IPaginatedViewAllRecurringPMTransactionResponse | null => packages.getRecurringTransactionsResponse(state.packages);

const setPackagesPackagesUpdateTransactionStatusAttempt = (state: RootState): boolean => packages.setPackagesUpdateTransactionStatusAttempt(state.packages);
const setPackagesPackagesUpdateTransactionStatusFailure = (state: RootState): string => packages.setPackagesUpdateTransactionStatusFailure(state.packages);
const setPackagesPackagesUpdateTransactionStatus = (state: RootState): string | null => packages.setPackagesUpdateTransactionStatus(state.packages);

const setPackagesEditTransactionAttempt = (state: RootState): boolean => packages.setPackagesEditTransactionAttempt(state.packages);
const setPackagesEditTransactionFailure = (state: RootState): string => packages.setPackagesEditTransactionFailure(state.packages);
const setPackagesEditTransaction = (state: RootState): string | null => packages.setPackagesEditTransaction(state.packages);

const setPackagesEditRecurringTransactionAttempt = (state: RootState): boolean => packages.setPackagesEditRecurringTransactionAttempt(state.packages);
const setPackagesEditRecurringTransactionFailure = (state: RootState): string => packages.setPackagesEditRecurringTransactionFailure(state.packages);
const setPackagesEditRecurringTransaction = (state: RootState): string | null => packages.setPackagesEditRecurringTransaction(state.packages);

const setPackagesPackagesCreatePMTransactionAttempt = (state: RootState): boolean => packages.setPackagesCreatePMTransactionAttempt(state.packages);
const setPackagesPackagesCreatePMTransactionFailure = (state: RootState): string => packages.setPackagesCreatePMTransactionFailure(state.packages);
const setPackagesPackagesCreatePMTransaction = (state: RootState): { id: string } | null => packages.setPackagesCreatePMTransaction(state.packages);

const packagesGetUpdateProgressDefectInspectionAttempt = (state: RootState): boolean => packages.getUpdateProgressDefectInspectionAttempt(state.packages);
const packagesGetUpdateProgressDefectInspectionFailure = (state: RootState): string => packages.getUpdateProgressDefectInspectionFailure(state.packages);

// Cobrokes
const getReaGetCobrokesAttempting = (state: RootState): boolean => rea.getCobrokesAttempting(state.rea);
const getReaGetCobrokesError = (state: RootState): string => rea.getCobrokesError(state.rea);
const getReaGetCobrokes = (state: RootState): AdminViewAllCoBrokeResponse => rea.getCobrokes(state.rea);
const getReaReviewCobrokesAttempting = (state: RootState): boolean => rea.reviewCobrokesAttempting(state.rea);
const getReaReviewCobrokesError = (state: RootState): string => rea.reviewCobrokesError(state.rea);

// Rea
const getReaGetReaAttempting = (state: RootState): boolean => rea.getReaAttempting(state.rea);
const getReaGetReaError = (state: RootState): string => rea.getReaError(state.rea);
const getReaReaReport = (state: RootState): GetREADetailsResponse => rea.getReaReport(state.rea);

const getReaGetReaDetailsAttempting = (state: RootState): boolean => rea.getReaDetailsAttempting(state.rea);
const getReaGetReaDetailsError = (state: RootState): string => rea.getReaDetailsError(state.rea);
const getReaReaDetails = (state: RootState): READetailsWithPropertyInfo | null => rea.getReaDetails(state.rea);

// Subadmin
const getSubAdminsAdminsReport = (state: RootState): ISubAdminsReport => subadmins.subAdminsReport(state.subadmins);
const getSubAdminsGetPropertyList = (state: RootState): ISubAdminPropertyWithId => subadmins.getPropertyList(state.subadmins);

const getSubAdminGetCreateNewSubAdminAttempt = (state: RootState): boolean => subadmins.getCreateNewSubAdminAttempt(state.subadmins);
const getSubAdminGetCreateNewSubAdminError = (state: RootState): string => subadmins.getCreateNewSubAdminError(state.subadmins);
const getSubAdminGetSubAdminReportAttempt = (state: RootState): boolean => subadmins.getSubAdminReportAttempt(state.subadmins);
const getSubAdminGetSubAdminReportError = (state: RootState): string => subadmins.getSubAdminReportError(state.subadmins);
const setSubAdminSetAssignPropertyLoading = (state: RootState): boolean => subadmins.setAssignPropertyAttempt(state.subadmins);
const setSubAdminSetAssignPropertyError = (state: RootState): string => subadmins.setAssignPropertyError(state.subadmins);
const setSubAdminsSetDeletePropertyLoading = (state: RootState): boolean => subadmins.setDeletePropertyAttempt(state.subadmins);
const setSubAdminsSetDeletePropertyError = (state: RootState): string => subadmins.setDeletePropertyError(state.subadmins);

const setSubAdminCreateSubAdminModal = (state: RootState): boolean => subadmins.setCreateSubAdminModal(state.subadmins);
const setSubAdminViewSubAdminPropertyModal = (state: RootState): boolean => subadmins.setViewSubAdminPropertyModal(state.subadmins);
const setSubAdminViewAddPropertyModal = (state: RootState): boolean => subadmins.setViewAddPropertyModal(state.subadmins);

// Packages
const setLeaseAndSalePackagesDocumentUploadId = (state: RootState): string => packages.setLeaseAndSalePackagesDocumentUploadId(state.packages);
const packagesSetLeaseSaleModal = (state: RootState): boolean => packages.setLeaseSaleModal(state.packages);

const getPackagesLeaseAndSaleDataDetailsSortAttempt = (state: RootState): boolean => packages.getPackagesLeaseAndSaleDataDetailsSortAttempt(state.packages);
const getPackagesLeaseAndSaleDataDetailsSortFailure = (state: RootState): string => packages.getPackagesLeaseAndSaleDataDetailsSortFailure(state.packages);
const getPackagesLeaseAndSaleDataDetailsSort = (state: RootState): ILeaseSale | null => packages.getPackagesLeaseAndSaleDataDetailsSort(state.packages);

const packagesSetPackagesDocumentViewModal = (state: RootState): boolean => packages.setPackagesDocumentViewModal(state.packages);

const packagesgetPackagesUploadUrlAttempting = (state: RootState): boolean => packages.getPackagesUploadUrlAttempting(state.packages);
const packagesgetPackagesUploadUrlError = (state: RootState): string => packages.getPackagesUploadUrlError(state.packages);
const packagesgetPackagesUploadUrl = (state: RootState): string => packages.getPackagesUploadUrl(state.packages);

const packagesUploadPackagesDocumentAttempting = (state: RootState): boolean => packages.uploadPackagesDocumentAttempting(state.packages);
const packagesUploadPackagesDocumentError = (state: RootState): string => packages.uploadPackagesDocumentError(state.packages);
const packagesSetDocumentUploadStatus = (state: RootState): boolean => packages.setDocumentUploadStatus(state.packages);

const packagesSetPackagesDocumentUploadId = (state: RootState): string => packages.setPackagesDocumentUploadId(state.packages);
const packagesSetPropertyManagementTransactionUploadDocumentCheck = (state: RootState): string => packages.setPropertyManagementTransactionUploadDocumentCheck(state.packages);

const packagesGetNegativeBalance = (state: RootState): number => packages.getNegativeBalance(state.packages);
const packagesGetDataToCreateTransaction = (state: RootState): SetCreatePMTransaction | null => packages.getDataToCreateTransaction(state.packages);
const packagesGetIsNegativeBalanceModalOpen = (state: RootState): boolean => packages.getIsNegativeBalanceModalOpen(state.packages);

const packagesGetPropertyManagementWithdrawalAttempt = (state: RootState): boolean => packages.getPropertyManagementWithdrawalAttempt(state.packages);
const packagesGetPropertyManagementWithdrawalFailure = (state: RootState): string | null => packages.getPropertyManagementWithdrawalFailure(state.packages);
const packagesGetPropertyManagementWithdrawal = (state: RootState): IPaginatedAdminPropertyManagementWithdrawalResponse => packages.getPropertyManagementWithdrawal(state.packages);

const packagesEditPropertyManagementWithdrawal = (state: RootState): boolean => packages.editPropertyManagementWithdrawalStatusAttempt(state.packages);

export default {
    // Auth
    getAuthStartupAttempting,
    getAuthStartupError,
    getAuthLoginAttempting,
    getAuthLoginError,
    getAuthAuthToken,
    getAuthGetUserInfo,
    getAuthGetAdminDashboard,
    getAuthGetAdminDashboardAttempt,
    getAuthGetAdminDashboardFailure,
    getAuthGetDailySettlement,
    getAuthGetDailySettlementAttempt,
    getAuthGetDailySettlementFailure,
    getAuthGetIsSuperAdmin,
    getAuthGetIsApproveSettlementModalOpen,

    // UI
    getUiSelectedLanguage,
    getUiSelectedTabView,
    getUiTransactionsFilter,
    getUiModulesCreateModal,
    getUiEditTransactionModal,
    getUiEditRecurringTransactionModal,
    getUiCreateOwnerModal,

    // Owners
    getOwnersCreateNewAttempt,
    getOwnersCreateNewError,

    getOwnersGetAllAttempt,
    getOwnersGetAllError,

    getOwnersOwnersReport,

    getOwnersGetOwnerDetailsAttempting,
    getOwnersGetOwnerDetailsError,

    getOwnersGetOwnerDetails,

    getOwnersEditOwnerDetailsAttempt,
    getOwnersEditOwnerDetailsError,

    // Property
    getPropertyGetAllAttempt,
    getPropertyGetAllError,

    getPropertyPropertyReport,

    getPropertyGetDetailsAttempt,
    getPropertyGetDetailsError,
    getPropertyPropertyDetails,

    getPropertySetServicePackageAttempting,
    getPropertySetServicePackageError,

    getPropertySetPropertyPackagesPriceAttempting,
    getPropertySetPropertyPackagesPriceError,

    getPropertySetGoogleDrivePathAttempting,
    getPropertySetGoogleDrivePathError,
    getPropertyGoogleDrivePath,

    getPropertyGetPropertyGroupAttempting,
    getPropertyGetPropertyGroupError,

    getPropertyPropertyGroup,

    getPropertyCreatePropertyGroupAttempting,
    getPropertyCreatePropertyGroupError,
    getPropertyPropertyGroupId,

    getPropertyDeletePropertyGroupAttempting,
    getPropertyDeletePropertyGroupError,

    getPropertyGetPropertyGroupDetailsAttempting,
    getPropertyGetPropertyGroupDetailsError,

    getPropertyPropertyGroupDetails,

    getPropertyEditPropertyGroupDetailsAttempting,
    getPropertyEditPropertyGroupDetailsError,

    getPropertyIsPropertyGroupList,

    getPropertyDuplicatePropertyGroupAttempt,
    getPropertyDuplicatePropertyGroupError,

    setIsPropertyOpenImagesCollapsible,
    setPropertySetPropertyImagesSubmitted,
    setPropertySetShowErrorMessage,

    getPropertyCreatePropertyAttempting,
    getPropertyCreatePropertyError,
    getPropertyPropertyId,

    getPropertyEditPropertyDetailsAttempting,
    getPropertyEditPropertyDetailsError,

    getPropertyEditPropertyPackagesDetailsAttempting,
    getPropertyEditPropertyPackagesDetailsError,

    getPropertyPropertyImagesUploadedFile,
    getPropertyPropertyImagesDeletedString,

    getPropertyGetServiceModalOpen,
    getPropertyGetPackagePriceModalOpen,
    getPropertyGetGoogleDrivePathModalOpen,
    getPropertyGetMilestoneModalOpen,
    getPropertyGetRejectProperyModalOpen,

    getPropertyGetSearchedPropertyAttempting,
    getPropertyGetSearchedPropertyError,
    getPropertySearchProperty,
    packagesSetVacantPossesionModal,

    setPropertySetPropertySetDescriptionAttempting,
    setPropertySetPropertySetDescriptionError,
    setPropertySetPropertySetDescription,

    setPropertySetPropertySetPropertyAmenitiesAttempting,
    setPropertySetPropertySetPropertyAmenitiesError,
    setPropertySetPropertySetPropertyAmenities,

    setPropertySetPropertySetPropertyDetailsAttempting,
    setPropertySetPropertySetPropertyDetailsError,
    setPropertySetPropertySetPropertyDetails,

    setPropertySetPropertySetPropertyUserAttempting,
    setPropertySetPropertySetPropertyUserError,
    setPropertySetPropertySetPropertyUser,

    setPropertySetPropertySetPropertyLocationAttempting,
    setPropertySetPropertySetPropertyLocationError,
    setPropertySetPropertySetPropertyLocation,

    setPropertyGetPropertySetCreatePropertAttempting,
    setPropertyGetPropertySetCreatePropertError,
    setPropertyGetPropertySetCreatePropert,

    setPropertyCreatePropertyModal,

    getPropertyGetSearchedOwnerAttempting,
    getPropertyGetSearchedOnerError,
    getPropertySearchedOwner,

    getPropertyGetCoordinates,
    getPropertyGetAddress1,
    getPropertyGetAddress2,
    getPropertyGetPoscode,
    getPropertyGetState,
    getPropertyGetCountry,
    getPropertyGetCity,

    userGetPropertyUploadUrlAttempting,
    userGetPropertyUploadUrlError,
    userGetPropertyUploadUrl,

    userUploadPropertyImageAttempting,
    userUploadPropertyImageError,
    setPropertysetIsImageUploadComplete,

    setPropertySetDeleteImageAttempting,
    setPropertySetDeleteImageError,
    setPropertySetDeleteImage,

    getPropertyGetPropertyDetailsAttempting,
    getPropertyGetPropertyDetailsError,
    getPropertyGetPropertyDetails,

    setPropertysIsPropertyDataLoaded,

    setPropertysLocationLatitudeFirstLoad,
    setPropertysLocationLongitudeFirstLoad,
    propertySetPropertyLocationSubmitted,

    setPropertySetPropertyTenancyStatusAttempting,
    setPropertySetPropertyTenancyStatusError,
    setPropertySetPropertyTenancyStatusSuccess,

    getPropertyGetVacancyStatusModalOpen,

    setPropertySetDuplicatePropertyAttempting,
    setPropertySetDuplicatePropertyError,
    setPropertySetDuplicatePropertySuccess,

    setPropertySearchTransaction,
    getPropertiesReportExportAttempt,

    groupPropertiesWithCount,
    groupPropertiesWithCountAttempt,

    // Payment
    getPaymentGetAllPaymentsAttempting,
    getPaymentGetAllPaymentsError,
    getPaymentGetAllPayments,

    getPaymentGetIsReceiptImagesModalOpen,

    getPaymentGetIsApproveRejectModalOpen,
    getPaymentGetApproveRejectParams,

    getPaymentApproveRejectPaymentAttempting,
    getPaymentApproveRejectPaymentError,

    getTransactionGetTransactionModalOpen,
    getTransactionGetUploadFileModalOpen,
    getTransactionGetFileViewModalOpen,
    getTransactionGetExportModalOpen,
    getTransactionGetConfirmRefundModalOpen,

    getTransactionGetFileList,
    getTransactionGetTransactionReportExportAttempt,
    // Recent events
    getPackagesCreateEventAttempting,
    getPackagesCreateEventError,
    getPackagesSelectedRecentEvent,

    getPackagesGetEventTemplateAttempting,
    getPackagesGetEventTemplateError,
    getPackagesEventTemplates,

    // Rea
    getReaGetCobrokesAttempting,
    getReaGetCobrokesError,
    getReaGetCobrokes,
    getReaReviewCobrokesAttempting,
    getReaReviewCobrokesError,
    getReaGetReaAttempting,
    getReaGetReaError,
    getReaReaReport,
    getReaGetReaDetailsAttempting,
    getReaGetReaDetailsError,
    getReaReaDetails,

    // subadmins
    getSubAdminGetCreateNewSubAdminAttempt,
    getSubAdminGetCreateNewSubAdminError,
    getSubAdminsAdminsReport,
    getSubAdminsGetPropertyList,
    getSubAdminGetSubAdminReportAttempt,
    getSubAdminGetSubAdminReportError,
    setSubAdminSetAssignPropertyLoading,
    setSubAdminSetAssignPropertyError,
    setSubAdminsSetDeletePropertyLoading,
    setSubAdminsSetDeletePropertyError,
    setSubAdminCreateSubAdminModal,
    setSubAdminViewSubAdminPropertyModal,
    setSubAdminViewAddPropertyModal,

    // Packages
    setLeaseAndSalePackagesDocumentUploadId,
    packagesSetLeaseSaleModal,
    getPackagesLeaseAndSaleDataDetailsSortAttempt,
    getPackagesLeaseAndSaleDataDetailsSortFailure,
    getPackagesLeaseAndSaleDataDetailsSort,

    packagesSetPackagesDocumentViewModal,

    packagesgetPackagesUploadUrlAttempting,
    packagesgetPackagesUploadUrlError,
    packagesgetPackagesUploadUrl,

    packagesUploadPackagesDocumentAttempting,
    packagesUploadPackagesDocumentError,
    packagesSetDocumentUploadStatus,

    packagesSetPackagesDocumentUploadId,
    packagesSetPropertyManagementTransactionUploadDocumentCheck,

    getPackagesDataVacantPossesionDetailsSortAttempt,
    getPackagesDataVacantPossesionDetailsSortFailure,
    getPackagesDataVacantPossesionDetailsSort,

    getPackagesDataDefectInspectionDetailsSortAttempt,
    getPackagesDataDefectInspectionDetailsSortFailure,
    getPackagesDataDefectInspectionDetailsSort,

    getPackagesDataVacantManagementDetailsSortAttempt,
    getPackagesDataVacantManagementDetailsSortFailure,
    getPackagesDataVacantManagementDetailsSort,

    getPackagesDataPropertyManagementDetailsSortAttempt,
    getPackagesDataPropertyManagementDetailsSortFailure,
    getPackagesDataPropertyManagementDetailsSort,

    getPackagesIPropertyManagementDataDetailsSortAttempt,
    getPackagesIPropertyManagementDataDetailsSortFailure,
    getIPropertyManagementDataDetailsSort,

    getPackagesViewAllTransactionsResponseAttempt,
    getPackagesViewAllTransactionsResponseFailure,
    getViewAllTransactionsResponse,

    getPackagesRecurringTransactionsResponseAttempt,
    getPackagesRecurringTransactionsResponseFailure,
    getPackagesRecurringTransactionsResponse,

    setPackagesPackagesUpdateTransactionStatusAttempt,
    setPackagesPackagesUpdateTransactionStatusFailure,
    setPackagesPackagesUpdateTransactionStatus,

    setPackagesEditTransactionAttempt,
    setPackagesEditTransactionFailure,
    setPackagesEditTransaction,

    setPackagesEditRecurringTransactionAttempt,
    setPackagesEditRecurringTransactionFailure,
    setPackagesEditRecurringTransaction,

    setPackagesPackagesCreatePMTransactionAttempt,
    setPackagesPackagesCreatePMTransactionFailure,
    setPackagesPackagesCreatePMTransaction,

    packagesGetUpdateProgressDefectInspectionAttempt,
    packagesGetUpdateProgressDefectInspectionFailure,

    packagesGetNegativeBalance,
    packagesGetDataToCreateTransaction,
    packagesGetIsNegativeBalanceModalOpen,

    packagesGetPropertyManagementWithdrawalAttempt,
    packagesGetPropertyManagementWithdrawalFailure,
    packagesGetPropertyManagementWithdrawal,

    packagesEditPropertyManagementWithdrawal,
};
