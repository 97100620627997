import React, { useEffect, useState } from 'react';
import config from 'config';
import * as ReactDom from 'react-dom';
import styled from 'styled-components';
import { IProperty } from '@dm/types';
import { Oval } from 'react-loader-spinner';

import { connect } from 'react-redux';
import { Colors } from '@dm/bigfish';

/* global google */

interface MapProps {
    propertyInfo: IProperty | null;
}

const Map = (props: MapProps) => {
    const { propertyInfo } = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
        loadGoogleMapScript(initAutocomplete);
    }, [propertyInfo?.location?.address1]);

    const loadGoogleMapScript = (callback) => {
        try {
            const googleMapScript = document.createElement('script');
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${config.dormeoInformations.mapApiKey}&libraries=places`;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener('load', callback);
            setLoading(true);
        } catch (e) {
            console.log('', e);
        }
    };

    function initAutocomplete() {
        let coordinateLat = 0;
        let coordinateLng = 0;
        firstLoad();
        function firstLoad() {
            if ((propertyInfo?.location?.address1.length)) {
                const currentLatitude = parseFloat(propertyInfo.location.lat ?? 0);
                const currentLongitude = parseFloat(propertyInfo.location.long ?? 0);
                const latlng = {
                    lat: currentLatitude,
                    lng: currentLongitude,
                };
                const geocoderFirstLoad = new google.maps.Geocoder();
                geocoderFirstLoad.geocode({ location: latlng }, (results, status) => {
                    if (status !== google.maps.GeocoderStatus.OK) {
                        console.log(status);
                        mapFunction();
                    }
                    // This is checking to see if the Geoeode Status is OK before proceeding
                    if (status === google.maps.GeocoderStatus.OK) {
                        coordinateLat = results[0].geometry.location.lat();
                        coordinateLng = results[0].geometry.location.lng();
                        mapFunction();
                    }
                });
            }
        }

        const mapFunction = () => {
            const myLatLng = { lat: coordinateLat, lng: coordinateLng };

            const map = new google.maps.Map(document.getElementById('map'), {
                center: myLatLng,
                zoom: 17,
                mapTypeId: 'roadmap',
                streetViewControl: false,
                mapTypeControl: false,
                FullscreenControlOptions: false,
                disableDefaultUI: true,
                panControl: true,
            });
            if ((coordinateLat !== 0) || (coordinateLng !== 0)) {
                let markers = [];

                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });
                markers = [];

                markers.push(new google.maps.Marker({
                    position: myLatLng,
                    map,
                    title: `${propertyInfo?.location?.address1 ?? ''}${propertyInfo?.location?.address2 ?? ''}${propertyInfo?.location?.city ?? ''}`,
                }));
            }
            setLoading(false);
        };
    }

    return (
        <div>
            <MapLoader>
                {loading && (
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                )}
            </MapLoader>
            <MarkerContainer>
                <div id='map' style={{ width: '100%', height: '100%', background: 'white' }} />
            </MarkerContainer>

        </div>
    );
};

const MapLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const MarkerContainer = styled.div`
    width: 100%;
    height: 300px;
    max-height: 300px;
    position: relative;
    margin-top: 20px;
`;

export default connect(null, null)(Map);
