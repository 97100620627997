import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import PackagesGateway from 'api/Packages';

import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import { SetUpdateTransactionStatusPayload } from 'redux/slices/packages/types';
import { PMTransactionTypeEnum, PropertyManagementTransactionStatus, SortByEnum } from '@dm/types';
import { toast } from 'react-toastify';
import Selectors from 'redux/Selectors';

export default function* watchTransactionStatusUpdateSort(api: PackagesGateway): SagaWatcherReturnType {
    yield takeEvery('packages/packagesUpdateTransactionStatusAttempt', handleUpdateStatusPackages, api);
}

function* handleUpdateStatusPackages(api: PackagesGateway, data: SetUpdateTransactionStatusPayload) {
    const authToken = Utils.Auth.getAuthToken();
    const transactionsFilter = yield* select(Selectors.getUiTransactionsFilter);

    if (authToken) {
        const {
            propertyId,
            transactionId,
            status,
        } = data.payload;

        const response = yield* call([api, api.setPackagesUpdateTransactionStatusResponse], {
            propertyId,
            transactionId,
            status,
            authToken,
        });

        if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
            if (!response.data) {
                yield put(Actions.packagesUpdateTransactionStatusFailure(Translate.t('GeneralUI.SomethingWrong')));
                return;
            }
            yield put(Actions.packagesUpdateTransactionStatusSuccess(response.data));
            yield put(Actions.setIsConfirmRefundModalOpen(false));
            toast.success(response.data);
            yield put(Actions.packagesGetIViewAllTransactionsResponseAttempt(transactionsFilter));
        } else {
        // handle error
            yield put(Actions.packagesUpdateTransactionStatusFailure(response.message));
        }
    }
}
